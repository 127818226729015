import { useState, useEffect } from 'react'
import getClubTeams from '../connector/getClubTeams.js'

function useClubTeams (clubId) {
  const [teams, setTeams] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let removed = false
    async function get () {
      if (!clubId) return
      setLoading(true)
      try {
        const teams = await getClubTeams(clubId).json()
        if (removed) return
        setTeams(teams)
        setLoading(false)
      } catch (error) {
        if (removed) return
        setError(error)
        setLoading(false)
      }
    }
    get()
    return () => { removed = true }
  }, [clubId])

  return [error, loading, teams]
}

export default useClubTeams
