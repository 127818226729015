import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { setSnackbarPosition } from '../../components/Snackbar/Snackbar'
import removeFalsyFromArray from '../../removeFalsyFromArray'
import { createPortal } from 'react-dom'
import EmbeddedCreateMember from './EmbeddedCreateMember'
import Header from '../../components/Header/Header'
import i18n from '../../translations/i18n'
import Text from '../../components/Text/Text'
import Button from '../../components/Button/Button'
import AttendeeRow from './AttendeeRow'

const AttendeesList = ({
  attendees,
  onChangeAttendees,
  supporters,
  onChangeSupporters,
  members,
  visible,
  onClose,
  onSave,
  reloadMembers
}) => {
  const [creatingMember, setCreatingMember] = useState(false)

  const recent = useMemo(() => {
    const sorted = [...members].sort((a, b) => {
      let num = 0
      if (a.activityScore < b.activityScore) num = 1
      if (a.activityScore > b.activityScore) num = -1
      return num
    })
    return sorted.slice(0, 15).reduce((accumulator, currentValue) => {
      return currentValue.activityScore ? [...accumulator, currentValue] : accumulator
    }, [])
  }, [members])

  useEffect(() => {
    setSnackbarPosition(0)
    return () => setSnackbarPosition(56)
  }, [])

  const classes = removeFalsyFromArray([
    'AttendeesList-root',
    visible && 'AttendeesList-visible'
  ])

  const handleToggle = useCallback((toggle) => {
    return (user) => () => {
      const newAttendees = new Set(attendees)
      const newSupporters = new Set(supporters)
      const userId = user._id
      if (toggle === 'all') {
        if (newAttendees.has(userId)) {
          newAttendees.delete(userId)
          newSupporters.add(userId)
        } else if (newSupporters.has(userId)) {
          newSupporters.delete(userId)
        } else {
          newAttendees.add(userId)
        }
      } else if (toggle === 'attendees') {
        if (newSupporters.has(userId)) {
          newSupporters.delete(userId)
        }
        if (newAttendees.has(userId)) {
          newAttendees.delete(userId)
        } else {
          newAttendees.add(userId)
        }
      } else if (toggle === 'supporters') {
        if (newAttendees.has(userId)) {
          newAttendees.delete(userId)
        }
        if (newSupporters.has(userId)) {
          newSupporters.delete(userId)
        } else {
          newSupporters.add(userId)
        }
      }

      onChangeAttendees(newAttendees)
      onChangeSupporters(newSupporters)
    }
  }, [attendees, onChangeAttendees, onChangeSupporters, supporters])

  const handleToggleAll = useMemo(() => {
    return handleToggle('all')
  }, [handleToggle])

  const handleToggleAttendees = useMemo(() => {
    return handleToggle('attendees')
  }, [handleToggle])

  const handleToggleSupporters = useMemo(() => {
    return handleToggle('supporters')
  }, [handleToggle])

  function handleCreateMemberClick () {
    setCreatingMember(true)
  }

  function handleCreateMemberClose () {
    setCreatingMember(false)
  }

  function handleMemberCreated (user) {
    setCreatingMember(false)
    handleToggleAttendees(user)()
    reloadMembers()
  }

  return createPortal(
    <div className={classes.join(' ')}>
      {creatingMember &&
      <EmbeddedCreateMember onMemberCreated={handleMemberCreated} onClose={handleCreateMemberClose}/>}
      <Header
        title={i18n.t('actions:add_attendees')}
        leftAction="back"
        onLeftAction={onClose}
        rightAction="addPerson"
        onRightAction={handleCreateMemberClick}
      />
      <div className="AttendeesList-body">
        <div className="AttendeesList-group-title">
          <Text size={12} transform="uppercase">{i18n.t('common:attendee')}</Text>
          <Text size={12} transform="uppercase">{i18n.t('common:recent')}</Text>
          <Text size={12} transform="uppercase">{i18n.t('common:supporter')}</Text>
        </div>
        <div className="AttendeesList-group-list">
          {
            recent.map(user => <AttendeeRow
                key={user._id}
                user={user}
                checkedAsAttendee={attendees.has(user._id)}
                checkedAsSupporter={supporters.has(user._id)}
                onAttendeeToggle={handleToggleAttendees(user)}
                onSupporterToggle={handleToggleSupporters(user)}
                onToggle={handleToggleAll(user)}
              />
            )
          }
        </div>
        <div className="AttendeesList-group-title">
          <Text size={12} transform="uppercase">{i18n.t('common:attendee')}</Text>
          <Text size={12} transform="uppercase">{i18n.t('common:alphabetic_a_-_z')}</Text>
          <Text size={12} transform="uppercase">{i18n.t('common:supporter')}</Text>
        </div>
        <div className="AttendeesList-group-list">
          {
            members.map(user => <AttendeeRow
                key={user._id}
                user={user}
                checkedAsAttendee={attendees.has(user._id)}
                checkedAsSupporter={supporters.has(user._id)}
                onAttendeeToggle={handleToggleAttendees(user)}
                onSupporterToggle={handleToggleSupporters(user)}
                onToggle={handleToggleAll(user)}
              />
            )
          }
        </div>
        <div className="AttendeesList-spacer"/>
      </div>
      <Button onClick={onSave}>{i18n.t('actions:save')}</Button>
    </div>,
    document.body
  )
}

export default AttendeesList
