import React from 'react'
import './Navigation.scss'
import Icon from '../Icon/Icon.js'
import Text from '../Text/Text.js'
import history from '../../history.js'
import i18n from '../../translations/i18n.js'

const Button = ({ name, icon, onClick, active }) =>
  <button className="Navigation-button" onClick={onClick}>
    <Icon name={icon} width={19} height={19} fill={active ? 'black' : 'grey'} />
    <Text size={10} color={active ? 'black' : 'grey'}>{name}</Text>
  </button>

const Navigation = ({ page }) => {
  function onNavClick (name) {
    return () => {
      history.replace(`/${name}`)
    }
  }
  return (
    <div className="Navigation-root">
      <Button icon="ball" active={page === 'activities'} onClick={onNavClick('activities')} name={i18n.t('common:activities')} />
      <Button icon="group" active={page === 'team'} onClick={onNavClick('team')} name={i18n.t('common:team')} />
    </div>
  )
}

export default Navigation
