function sortArrayOfUsers (users) {
  return [...users].sort((a, b) => {
    const aName = (a.name || '').toLowerCase()
    const bName = (b.name || '').toLowerCase()
    let num = 0
    if (aName < bName) num = -1
    if (aName > bName) num = 1
    return num
  })
}

export default sortArrayOfUsers
