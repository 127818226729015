import { useState, useEffect } from 'react'
import getUsers from '../connector/getUsers.js'

function useUsers (teamId) {
  const [users, setUsers] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [reloadCounter, forceReload] = useState(0)

  function reload () {
    forceReload(reloadCounter + 1)
  }

  useEffect(() => {
    let removed = false
    async function get () {
      setLoading(true)
      try {
        const users = await getUsers({ teamId }).json()
        if (removed) return
        setUsers(users)
        setLoading(false)
      } catch (error) {
        if (removed) return
        setError(error)
        setLoading(false)
      }
    }
    get()
    return () => { removed = true }
  }, [teamId, reloadCounter])

  return [error, loading, users, reload]
}

export default useUsers
