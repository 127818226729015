import React, { useEffect, useState } from 'react'
import './AddAttendees.scss'
import Text from '../../components/Text/Text.js'
import Icon from '../../components/Icon/Icon.js'
import i18n from '../../translations/i18n.js'
import isEqual from 'lodash.isequal'
import Modal from '../../components/Modal/Modal'
import Button, { ButtonRow } from '../../components/Button/Button'
import AttendeesList from './AttendeesList'
import AttendeesDisplay from './AttendeesDisplay'

const AddAttendees = ({ members, attendees, onChangeAttendees, supporters, onChangeSupporters, reloadMembers }) => {
  const [visible, setVisible] = useState(false)
  const [newAttendees, setNewAttendees] = useState(new Set(attendees))
  const [newSupporters, setNewSupporters] = useState(new Set(supporters))
  const [showBackWarning, setShowBackWarning] = useState(false)

  useEffect(() => {
    if (visible) {
      setNewAttendees(new Set(attendees))
      setNewSupporters(new Set(supporters))
    }
  }, [attendees, supporters, visible])

  const handleSave = () => {
    onChangeAttendees(newAttendees)
    onChangeSupporters(newSupporters)
    setVisible(false)
  }

  const handleClose = () => {
    if (isEqual(attendees, newAttendees) && isEqual(supporters, newSupporters)) {
      setVisible(false)
    } else {
      setShowBackWarning(true)
    }
  }

  const handleGoBackConfirm = () => {
    setNewAttendees(new Set(attendees))
    setNewSupporters(new Set(supporters))
    setShowBackWarning(false)
    setVisible(false)
  }

  return (
    <>
      <Modal active={showBackWarning} onClose={() => setShowBackWarning(false)}>
        <div className="CreateMember-modal">
          <div className="CreateMember-modal-warning">
            <Icon name="danger" fill="var(--red)" width={26} height={26}/>
          </div>
          <div className="CreateActivity-back-modal">
            <Text size={14}>{i18n.t('messages:dont_save_warning')}</Text>
          </div>
          <ButtonRow>
            <Button onClick={handleGoBackConfirm}>{i18n.t('actions:delete')}</Button>
            <Button onClick={() => setShowBackWarning(false)}>{i18n.t('actions:keep_editing')}</Button>
          </ButtonRow>
        </div>
      </Modal>
      <AttendeesList
        members={members}
        visible={visible}
        onClose={handleClose}
        attendees={newAttendees}
        onChangeAttendees={setNewAttendees}
        supporters={newSupporters}
        onChangeSupporters={setNewSupporters}
        onSave={handleSave}
        reloadMembers={reloadMembers}
      />
      <div className="AddAttendees-root" onClick={() => setVisible(true)}>
        <AttendeesDisplay
          members={members}
          attendees={attendees}
          singular={i18n.t('common:attendee')}
          plural={i18n.t('common:attendees')}
          showAddAction={true}
        />
        <AttendeesDisplay
          members={members}
          attendees={supporters}
          singular={i18n.t('common:supporter')}
          plural={i18n.t('common:supporters')}
        />
      </div>
    </>
  )
}

export default AddAttendees
