import React, { useState, useEffect, useRef } from 'react'
import './SnackbarRow.scss'
import PropTypes from 'prop-types'
import Text from '../Text/Text.js'
import Icon from '../Icon/Icon.js'
import removeFalsyFromArray from '../../removeFalsyFromArray.js'

const levels = {
  error: {
    icon: <Icon name="danger" width={24} height={24} fill="var(--white)" />,
    color: 'red'
  },
  success: {
    icon: <Icon name="check" width={21} height={21} fill="var(--white)" />,
    color: 'green'
  }
}

const SnackbarRow = ({ message, level, action, onAction, expiresAt, onClose, onCloseDone, show, ...rest }) => {
  const intervalRef = useRef(null)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    let unmounted = false
    if (show && !visible) {
      setTimeout(() => {
        if (unmounted) return
        setVisible(true)
      }, 10)
    } else if (!show && visible) {
      setTimeout(() => {
        if (unmounted) return
        setVisible(false)
        setTimeout(() => {
          onCloseDone()
        }, 500)
      }, 10)
    }
    return () => { unmounted = true }
  }, [show, visible, onCloseDone])

  useEffect(() => {
    if (!expiresAt) return
    clearInterval(intervalRef.current)
    intervalRef.current = setInterval(() => {
      if (expiresAt < new Date().getTime()) {
        clearInterval(intervalRef.current)
        onClose()
      }
    }, 100)
    return () => clearInterval(intervalRef.current)
  }, [expiresAt, onClose])

  const { icon, color } = levels[level]
  const classes = [
    'SnackbarRow-root',
    `SnackbarRow-${color}`
  ]

  const wrapperClasses = removeFalsyFromArray([
    'SnackbarRow-wrapper',
    visible && 'SnackbarRow-show'
  ])

  return (
    <div className={wrapperClasses.join(' ')}>
      <div className={classes.join(' ')} onClick={onClose}>
        <div className="SnackbarRow-icon">{icon}</div>
        <div className="SnackbarRow-message"><Text color="white" size={12} weight="bold">{message}</Text></div>
        { action && <button className="SnackbarRow-action" onClick={onAction}><Text color="white" size={12} weight="bold">{action}</Text></button> }
      </div>
    </div>
  )
}

SnackbarRow.propTypes = {
  message: PropTypes.string,
  level: PropTypes.oneOf(['error', 'success']),
  action: PropTypes.string,
  onAction: PropTypes.func,
  expiresAt: PropTypes.number,
  onClose: PropTypes.func
}

export default SnackbarRow
