import React, { useState } from 'react'
import './Select.scss'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon.js'
import removeFalsyFromArray from '../../removeFalsyFromArray.js'
import Modal from '../Modal/Modal.js'
import List, { ListItem } from '../List/List.js'
import Text from '../Text/Text.js'

const Select = ({ onChange, label, iconName, iconColor = 'grey', required, value = {}, error, items = [], disabled, onFocus = () => {}, onBlur = () => {} }) => {
  const [showModal, setShowModal] = useState(false)

  const classes = removeFalsyFromArray([
    'Select-root',
    error && 'Select-error',
    disabled && 'Select-disabled'
  ])

  function handleChange (id) {
    return e => {
      e.stopPropagation()
      setShowModal(false)
      onBlur()
      if (disabled) return
      if (!id) return onChange(e.target.value)
      onChange(id)
    }
  }

  function handleRowClick () {
    if (disabled) return
    setShowModal(true)
    onFocus()
  }

  function handleCloseClick () {
    setShowModal(false)
    onBlur()
  }

  return (
    <>
      <Modal onClose={handleCloseClick} active={showModal}>
        <div className="Select-list">
          <List>
            { items.map(item =>
              <ListItem key={item.id} name={item.label} onClick={handleChange(item.id)} comment={item.comment} iconName={item.iconName} iconColor={item.iconColor} />
            ) }
          </List>
        </div>
      </Modal>
      <div className={classes.join(' ')} onClick={handleRowClick}>
        <div className="Select-icon">
          {iconName && <Icon width={16} height={16} name={iconName} fill={`var(--${iconColor})`} />}
          {required && !disabled && <div className="Select-icon-required"><Icon name="asterisk" width={8} height={8} fill="var(--grey)" /></div>}
        </div>
        <select onChange={handleChange()} value={value.id || ''}>
          { items.map(item => <option key={item.id} value={item.id}>{item.label}</option>) }
        </select>
        <div className="Select-label">
          { value.label
            ? <Text size={14} color={error ? 'red' : 'black'}>{value.label}</Text>
            : <Text size={14} color={error ? 'red' : 'grey'}>{label}</Text>
          }
        </div>
        <div className="Select-warning-icon"><Icon name="danger" fill="var(--red)" width={24} height={24} /></div>
      </div>
    </>
  )
}

Select.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.object,
  error: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    comment: PropTypes.string,
    iconName: PropTypes.string,
    iconColor: PropTypes.string
  })),
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
}

export default Select
