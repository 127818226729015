import React from 'react'
import './Install.scss'
import AndroidOpera from './AndroidOpera.js'
import AndroidNoSupport from './AndroidNoSupport.js'
import AndroidSamsung from './AndroidSamsung.js'
import AndroidFirefox from './AndroidFirefox.js'
import IOSNotSafari from './IOSNotSafari.js'
import IOSSafari from './IOSSafari.js'
import Text from '../../components/Text/Text.js'
import Icon from '../../components/Icon/Icon.js'
import history from '../../history.js'
import Bowser from 'bowser'

const Install = () => {
  const bowser = Bowser.parse(window.navigator.userAgent)
  const os = bowser.os.name
  const browser = bowser.browser.name

  let content
  switch (true) {
    case (os === 'Android' && browser === 'Opera'): {
      content = <AndroidOpera />
      break
    }
    case (os === 'Android' && browser === 'Samsung Internet for Android'): {
      content = <AndroidSamsung />
      break
    }
    case (os === 'Android' && browser === 'Firefox'): {
      content = <AndroidFirefox />
      break
    }
    case (os === 'iOS' && browser === 'Safari'): {
      return <IOSSafari />
    }
    case (os === 'iOS' && browser !== 'Safari'): {
      content = <IOSNotSafari />
      break
    }
    default: {
      content = <AndroidNoSupport />
      break
    }
  }

  function handleCloseClick () {
    history.goBack()
  }

  return (
    <div className="Install-root">
      <button onClick={handleCloseClick} className="Install-close"><Icon name="cross" width={12} height={12} /></button>
      <div className="Install-content">
        {content}
      </div>
      <div className="Install-logo">
        <Icon name="mobileIcon" width={44} height={44} />
        <Text transform="uppercase" weight="bold" size={8}>Younited</Text>
      </div>
    </div>
  )
}

export default Install
