const sizeItems = [
  {
    id: 'xs',
    label: 'XS'
  },
  {
    id: 's',
    label: 'S'
  },
  {
    id: 'm',
    label: 'M'
  },
  {
    id: 'l',
    label: 'L'
  },
  {
    id: 'xl',
    label: 'XL'
  },
  {
    id: 'xxl',
    label: 'XXL'
  },
  {
    id: 'xxxl',
    label: 'XXXL'
  }
]

function getSizeItems () {
  return sizeItems
}

export default getSizeItems
