import store from './redux/store.js'

let previousColors = {
  primary: 'black',
  primaryContrast: 'white'
}

store.subscribe(() => {
  const state = store.getState()
  let primary, primaryContrast
  try {
    primary = state.user.user.club.colors.primaryColor
    primaryContrast = state.user.user.club.colors.contrastColor
  } catch (error) {}
  if (((previousColors.primary !== primary) || (previousColors.primaryContrast !== primaryContrast)) && (primary && primaryContrast)) {
    console.log('colors changed', previousColors, primary, primaryContrast)
    previousColors = {
      primary,
      primaryContrast
    }

    const innerHTML = `
      :root {
        --primary: ${primary};
        --primary-contrast: ${primaryContrast};
      }
    `

    const existingStyleTag = document.getElementById('dynamic-colors')
    if (existingStyleTag) {
      existingStyleTag.innerHTML = innerHTML
    } else {
      const newStyleTag = document.createElement('style')
      newStyleTag.id = 'dynamic-colors'
      newStyleTag.innerHTML = innerHTML
      document.head.append(newStyleTag)
    }
  }
})
