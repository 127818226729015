import React from 'react'
import './List.scss'
import PropTypes from 'prop-types'
import ListItem from './ListItem.js'

const List = ({ children, depth = 0 }) => <ul className="List-root" data-depth={depth}>{children}</ul>

List.propTypes = {
  children: PropTypes.node,
  depth: PropTypes.number
}

export default List
export { ListItem }
