import host from '../host.js'
import get from './get.js'

function getActivities ({ teamId, from, to }) {
  const searchParams = new window.URLSearchParams()
  if (teamId) searchParams.set('teams', teamId)
  if (from) searchParams.set('from', from)
  if (to) searchParams.set('to', to)
  const url = `${host}/activity?${searchParams.toString()}`
  return get(url)
}

export default getActivities
