import React from 'react'
import './Activity.scss'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon.js'
import Text from '../Text/Text.js'
import removeFalsyFromArray from '../../removeFalsyFromArray.js'
import { getActivities } from '../../common/activityItems.js'

const Activity = ({ name, amount, solid, description, onClick, tooSoon }) => {
  const activities = getActivities()
  const info = activities.get(name)
  const primaryColor = solid ? 'white' : info.iconColor
  const secondaryColor = info.iconColor
  const classes = removeFalsyFromArray([
    'Activity-root',
    `Activity-${secondaryColor}`,
    solid && 'Activity-solid'
  ])
  return (
    <div className={classes.join(' ')} onClick={onClick}>
      <Icon name={info.iconName} width={16} height={16} fill={`var(--${primaryColor})`} />
      <div className="Activity-text">
        <Text size={12} weight="medium" color={primaryColor}>{info.label}</Text>
        { description && <Text size={12} weight="light" font="roboto" color={primaryColor}>{description}</Text> }
      </div>
      { tooSoon
        ? <Icon name="clock" width={18} height={18} fill={`var(--${primaryColor})`} />
        : <Text size={15} color={primaryColor}>{amount || '?'}</Text>
      }
    </div>
  )
}

Activity.propTypes = {
  name: PropTypes.oneOf([
    'training',
    'friendlyGame',
    'teambuilding',
    'socialActivity',
    'younited'
  ]),
  amount: PropTypes.number,
  solid: PropTypes.bool,
  description: PropTypes.string,
  onClick: PropTypes.func,
  tooSoon: PropTypes.bool
}

export default Activity
