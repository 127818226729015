function setTeamId (id) {
  window.localStorage.setItem('teamId', id)
}

function getTeamId () {
  return window.localStorage.getItem('teamId')
}

function removeTeamId () {
  return window.localStorage.removeItem('teamId')
}

export { setTeamId, getTeamId, removeTeamId }
