import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import nl from './languages/nl_be/index.js'
import fr from './languages/fr_be/index.js'

i18next
  .use(LanguageDetector)
  .init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'nl',
    ns: ['login', 'activities', 'team', 'settings', 'createMember', 'createActivity', 'editUser', 'common', 'mobileWarning', 'installation'],
    defaultNS: 'common',
    whitelist: ['nl', 'fr', 'en'],
    nonExplicitWhitelist: true,
    resources: {
      nl,
      fr
    },
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage']
    },
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  }, function (error, t) {
    if (error) {
      console.warn('i18next error', error)
    }
  })

export default i18next
