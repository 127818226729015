import { CreateSnackbarItem } from './components/Snackbar/Snackbar.js'

async function handleError (error) {
  let message = ''
  try {
    const response = await error.response.json()
    message = response.message
    if (response.error && response.error.error && response.error.error.message) {
      message += ' - ' + response.error.error.message
    }
  } catch (responseError) {
    message = error.toString()
  }
  CreateSnackbarItem({
    message,
    level: 'error'
  })
}

export default handleError
