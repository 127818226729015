import React, { useState } from 'react'
import './Login.scss'
import history from '../../history.js'
import { setToken } from '../../token.js'
import login from '../../connector/login.js'
import Input, { InputContainer } from '../../components/Input/Input.js'
import Button from '../../components/Button/Button.js'
import Text from '../../components/Text/Text.js'
import Icon from '../../components/Icon/Icon.js'
import { CreateSnackbarItem } from '../../components/Snackbar/Snackbar.js'
import i18n from '../../translations/i18n.js'
import initializeUser from '../../initializeUser.js'
import useScrollToInputOnResize from '../../customHooks/useScrollToInputOnResize.js'
import Version from '../../components/Version/Version'

const Login = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  useScrollToInputOnResize()

  async function handleSubmit (e) {
    e.preventDefault()
    try {
      const response = await login(username, password).json()
      setToken(response.token)
      await initializeUser()
      history.push('/activities')
    } catch (error) {
      setError(error)
    }
  }

  let invalidCredentials = false
  if (error) {
    if (error.response && error.response.status) {
      CreateSnackbarItem({
        message: i18n.t('errors:invalid_credentials'),
        level: 'error'
      })
      invalidCredentials = true
    } else {
      CreateSnackbarItem({
        message: `Unhandled error: ${error.message}`,
        level: 'error'
      })
    }
  }

  function handleChange (setter) {
    return e => {
      setError(null)
      setter(e.target.value)
    }
  }

  function handlePasswordResetClick () {
    history.push('/requestPasswordReset')
  }

  return (
    <form onSubmit={handleSubmit} className="Login-root">
      <Version/>
      <div className="Login-body">
        <div className="Login-head">
          <Icon name="mobileIcon" width={44} height={44} />
          <Text size={8} weight="bold" transform="uppercase">{i18n.t('common:younited')}</Text>
          <Text size={16} weight="bold">{i18n.t('actions:login')}</Text>
        </div>
        <InputContainer>
          <Input error={invalidCredentials} label={i18n.t('messages:enter_your_email')} iconName="person" value={username} onChange={handleChange(setUsername)} />
          <Input error={invalidCredentials} label={i18n.t('messages:enter_your_password')} iconName="key" value={password} type="password" onChange={handleChange(setPassword)} />
        </InputContainer>
        <div className="Login-forgot-password">
          <button type="button" onClick={handlePasswordResetClick}><Text size={12} weight="bold">{i18n.t('actions:forgot_password')}</Text></button>
        </div>
      </div>
      <Button onClick={handleSubmit} type="submit">{i18n.t('actions:login')}</Button>
    </form>
  )
}

export default Login
