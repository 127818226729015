import React, { useState, useMemo } from 'react'
import { createPortal } from 'react-dom'
import './Team.scss'
import i18n from '../../translations/i18n.js'
import { connect } from 'react-redux'
import Header from '../../components/Header/Header.js'
import Navigation from '../../components/Navigation/Navigation.js'
import history from '../../history.js'
import Button from '../../components/Button/Button.js'
import Text from '../../components/Text/Text.js'
import List, { ListItem } from '../../components/List/List.js'
import useUsers from '../../customHooks/useUsers.js'
import getRoleIcon from '../../getRoleIcon.js'
import SelectTeamModal from '../../components/SelectTeamModal/SelectTeamModal.js'
import sortArrayOfUsers from '../../sortArrayOfUsers.js'
import Loader from '../../components/Loader/Loader.js'
import { setAppState } from '../../redux/appState/actions.js'
import { CreateSnackbarItem } from '../../components/Snackbar/Snackbar.js'
import { setTeamId } from '../../currentTeamId.js'
import { useSwipeable } from 'react-swipeable'
import removeFalsyFromArray from '../../removeFalsyFromArray.js'
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture'

const Team = ({ userState, team, teams, setTeam }) => {
  const teamId = team && team._id
  const [membersError, membersLoading, members] = useUsers(teamId)
  const [showTeamModal, setShowTeamModal] = useState(false)
  const [swipePosition, setSwipePosition] = useState(false)

  function handleSwiping (e) {
    if (e.deltaX < 20 && e.absX > e.absY) {
      if (!swipePosition) setSwipePosition(true)
    } else {
      if (swipePosition) setSwipePosition(false)
    }
  }
  function handleSwipe (e) {
    history.replace('/activities')
  }
  const handlers = useSwipeable({ onSwiped: () => setSwipePosition(false), onSwipedRight: handleSwipe, onSwiping: handleSwiping, delta: 20 })

  const sortedMembers = useMemo(() => {
    return sortArrayOfUsers(members)
  }, [members])

  const recent = useMemo(() => {
    const sorted = [...members].sort((a, b) => {
      let num = 0
      if (a.activityScore < b.activityScore) num = 1
      if (a.activityScore > b.activityScore) num = -1
      return num
    })
    return sorted.slice(0, 15).reduce((accumulator, currentValue) => {
      return currentValue.activityScore ? [...accumulator, currentValue] : accumulator
    }, [])
  }, [members])

  function handleSettingsClick (e) {
    history.push('/settings')
  }

  function handleAddClick (e) {
    history.push('/createMember')
  }

  function handleEditClick (user) {
    return e => {
      history.push(`/editMember/${user._id}`)
    }
  }

  function handleSwitchClick () {
    if (teams.length === 2) {
      const teamId = team._id
      const newTeam = teams.find(team => team._id !== teamId)
      setTeam(newTeam)
      setTeamId(newTeam._id)
      CreateSnackbarItem({
        message: i18n.t('messages:team_set_to', { teamName: newTeam.name || '' }),
        level: 'success'
      })
    } else {
      setShowTeamModal(true)
    }
  }

  function handleTeamModalClose () {
    setShowTeamModal(false)
  }

  const loading = userState.loading || membersLoading
  const error = userState.error // TODO handle error
  if (!userState.user) return null // TODO handle missing user

  const bodyClasses = removeFalsyFromArray(['Team-body', swipePosition && 'Team-swiping'])

  return (
    <div className="Team-root">
      { error && <div>{error.toString()}</div> }
      <SelectTeamModal active={showTeamModal} onClose={handleTeamModalClose} />
      <Header
        title={team.name}
        leftAction='settings'
        onLeftAction={handleSettingsClick}
        rightAction={teams.length > 1 ? 'switch' : null}
        onRightAction={handleSwitchClick}
      />
      <div className={bodyClasses.join(' ')} {...handlers} style={{ left: swipePosition }}>
        { loading && <Loader fullSize={true} /> }
        <div>
          <div className="Team-list-header">
            <Text size={12} transform="uppercase">{i18n.t('common:recent')}</Text>
          </div>
          <List>
            { membersError && <div>{membersError.toString()}</div> }
            { recent.map(user => {
              return <ListItem
                key={user._id}
                iconName={getRoleIcon(user.role)}
                beforeName={<ProfilePicture value={user.profilePicture} />}
                name={user.name}
                onClick={handleEditClick(user)}
              />
            }) }
          </List>
        </div>
        <div>
          <div className="Team-list-header">
            <Text size={12} transform="uppercase">{i18n.t('common:alphabetic_a_-_z')}</Text>
          </div>
          <List>
            { membersError && <div>{membersError.toString()}</div> }
            { sortedMembers.map(user => {
              return <ListItem
                key={user._id}
                iconName={getRoleIcon(user.role)}
                beforeName={<ProfilePicture value={user.profilePicture} />}
                name={user.name}
                onClick={handleEditClick(user)}
              />
            }) }
          </List>
        </div>
        <CreateActivityButton handleAddClick={handleAddClick} />
      </div>
      <Navigation page="team" />
    </div>
  )
}

const CreateActivityButton = ({ handleAddClick }) => {
  if (!document.getElementById('floating-button')) return null
  return createPortal(
    <div>
      <Button circle={true} onClick={handleAddClick}>{i18n.t('actions:add_attendee')}</Button>
    </div>,
    document.getElementById('floating-button')
  )
}

function mapStateToProps (state) {
  const user = state.user.user || {}
  return {
    userState: state.user,
    team: state.appState.team,
    teams: user.teams || []
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setTeam: team => dispatch(setAppState({ team }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Team)
