import React, { useEffect, useState } from 'react'
import './ProfilePicture.scss'
import PropTypes from 'prop-types'
import Modal from '../Modal/Modal'

const ProfilePicture = ({ value, onFocus = () => {}, onBlur = () => {} }) => {
  const [base64Value, setBase64Value] = useState(null)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (value?.type === 'Buffer' && Array.isArray(value?.data)) {
      const data = Buffer.from(value.data).toString('base64')
      setBase64Value(`data:image/png;base64,${data}`)
    } else {
      setBase64Value(null)
    }
  }, [value])

  function handleShowFullSizeModal (event) {
    event.stopPropagation()
    event.preventDefault()
    setShowModal(true)
    onFocus()
  }

  function handleCloseFullSizeModal (event) {
    event?.stopPropagation()
    setShowModal(false)
    onBlur()
  }

  return <div className="ProfilePicture-root">
    {
      base64Value && <>
          <div className="ProfilePicture-thumbnail" onClick={handleShowFullSizeModal}>
            <img src={base64Value} alt=""/>
          </div>
          <Modal onClose={handleCloseFullSizeModal} active={showModal}>
            <div className="ProfilePicture-full-size" onClick={handleCloseFullSizeModal}>
              <img src={base64Value} alt=""/>
            </div>
          </Modal>
        </>
    }
  </div>
}

ProfilePicture.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.oneOf(['Buffer']).isRequired,
      data: PropTypes.arrayOf(PropTypes.number).isRequired
    }),
    PropTypes.instanceOf(File)
  ]),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
}

export default ProfilePicture
