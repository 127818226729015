import React from 'react'
import './MobileWarning.scss'
import Text from '../Text/Text.js'
import Icon from '../Icon/Icon.js'
import QRCode from '../QRCode.js'
import i18n from '../../translations/i18n.js'

const MobileWarning = () => {
  return (
    <div className="MobileWarning" id="mobile-warning">
      <Icon width={50} height={50} name="mobile" fill="var(--grey)" />
      <Text size={12} weight="bold">{i18n.t('errors:only_mobile_message')}</Text>
      <Text size={12} weight="bold">{i18n.t('errors:scan_qr_code_message')}</Text>
      <QRCode width={300} height={300} />
      <Icon width={50} height={50} name="mobileIcon" />
      <Text size={8} weight="bold" transform="uppercase">{i18n.t('common:younited')}</Text>
    </div>
  )
}

export default MobileWarning
