import i18n from '../translations/i18n.js'

let activityItems = []
let activities = new Map()
function translateActivityItems () {
  activityItems = [
    {
      id: 'training',
      label: i18n.t('activityTypes:training'),
      iconName: 'soccer',
      iconColor: 'blue'
    },
    {
      id: 'friendlyGame',
      label: i18n.t('activityTypes:friendly_game'),
      iconName: 'cup',
      iconColor: 'orange'
    },
    {
      id: 'socialActivity',
      label: i18n.t('activityTypes:social_activity'),
      iconName: 'heart',
      iconColor: 'purple'
    },
    {
      id: 'teambuilding',
      label: i18n.t('activityTypes:teambuilding'),
      iconName: 'group',
      iconColor: 'green',
      comment: i18n.t('common:teambuilding_comment')
    },
    {
      id: 'younited',
      label: i18n.t('activityTypes:younited_activity'),
      iconName: 'shield',
      iconColor: 'red'
    }
  ]
  activities = new Map(activityItems.map(item => [item.id, item]))
}
translateActivityItems()
i18n.on('languageChanged', () => {
  translateActivityItems()
})

function getActivityItems () {
  return activityItems
}

function getActivities () {
  return activities
}

export {
  getActivityItems,
  getActivities
}
