import React from 'react'
import './Text.scss'
import PropTypes from 'prop-types'

const Text = ({ children, color = 'black', size = 12, weight = 'normal', transform, font = 'navigo' }) => {
  const classes = [
    'Text-root',
    `Text-color-${color}`,
    `Text-weight-${weight}`,
    `Text-font-${font}`,
    transform && 'Text-uppercase'
  ]
  return <p className={classes.join(' ')} style={{ fontSize: size }}>{children}</p>
}

Text.propTypes = {
  color: PropTypes.oneOf(['black', 'orange', 'purple', 'green', 'blue', 'red', 'grey', 'white', 'primary', 'primary-contrast']),
  children: PropTypes.node,
  size: PropTypes.number,
  weight: PropTypes.oneOf(['light', 'normal', 'medium', 'bold']),
  transform: PropTypes.oneOf(['uppercase']),
  font: PropTypes.oneOf(['navigo', 'roboto'])
}

export default Text
