if ('serviceWorker' in navigator) {
  // Use the window load event to keep the page load performant
  window.addEventListener('load', () => {
    if (window.location.hostname === 'localhost') {
      false && navigator.serviceWorker.register('/serviceWorker.js') // set to true to test serviceWorker on localhost
      return
    }

    console.log('registering service worker')
    navigator.serviceWorker.register('/serviceWorker.js')
  })
}
