import React, { useState } from 'react'
import './Settings.scss'
import i18n from '../../translations/i18n.js'
import Header from '../../components/Header/Header.js'
import history from '../../history.js'
import List, { ListItem } from '../../components/List/List.js'
import Modal from '../../components/Modal/Modal.js'
import handleLogout from '../../handleLogout.js'
import SelectTeamModal from '../../components/SelectTeamModal/SelectTeamModal.js'
import { connect } from 'react-redux'
import Text from '../../components/Text/Text.js'
import Icon from '../../components/Icon/Icon.js'
import { setAppState } from '../../redux/appState/actions.js'
import { CreateSnackbarItem } from '../../components/Snackbar/Snackbar.js'
import { setTeamId } from '../../currentTeamId.js'
import host from '../../host'
import Version from '../../components/Version/Version'
import { changeLanguage as changeDateFnsLanguage } from '../../format.js'

const languages = [
  {
    label: 'Nederlands',
    value: 'nl'
  },
  {
    label: 'Français',
    value: 'fr'
  }
]

const Settings = ({ teams, setTeam, club, team }) => {
  const [showLanguageModal, setShowLanguageModal] = useState(false)
  const [showTeamModal, setShowTeamModal] = useState(false)
  const [loggingOut, setLoggingOut] = useState(false)

  if (loggingOut) return null

  function handleBackClick (e) {
    history.goBack()
  }

  function handleLanguageChange (language) {
    return e => {
      setShowLanguageModal(false)
      changeDateFnsLanguage(language)
      i18n.changeLanguage(language)
    }
  }

  function handleLogoutClick () {
    setLoggingOut(true)
    handleLogout()
  }

  function handleLanguageButtonClick () {
    setShowLanguageModal(true)
  }

  function handleTeamSelectClick () {
    setShowTeamModal(true)
  }

  function handleTeamSwitchClick () {
    const teamId = team._id
    const newTeam = teams.find(team => team._id !== teamId)
    setTeam(newTeam)
    setTeamId(newTeam._id)
    CreateSnackbarItem({
      message: i18n.t('messages:team_set_to', { teamName: newTeam.name || '' }),
      level: 'success'
    })
  }

  function handleEditUserClick () {
    history.push('/editUser')
  }

  function handleLanguageModalClose () {
    setShowLanguageModal(false)
  }

  function handleTeamModalClose () {
    setShowTeamModal(false)
  }

  return (
    <div>
      <Modal onClose={handleLanguageModalClose} active={showLanguageModal}>
        <div className="Select-list">
          <List>
            { languages.map(language =>
              <ListItem key={language.value} name={language.label} onClick={handleLanguageChange(language.value)} />
            ) }
          </List>
        </div>
      </Modal>
      <SelectTeamModal active={showTeamModal} onClose={handleTeamModalClose} />
      <Header
        title={i18n.t('common:settings')}
        subTitle={team.name}
        leftAction='back'
        onLeftAction={handleBackClick}
      />
      <div className="Settings-body">
        <List>
          <ListItem name={i18n.t('common:language')} onClick={handleLanguageButtonClick} />
          <ListItem name={i18n.t('common:my_credentials')} onClick={handleEditUserClick} />
          { teams.length === 2 && <ListItem name={i18n.t('actions:switch_team')} onClick={handleTeamSwitchClick} /> }
          { teams.length > 2 && <ListItem name={i18n.t('actions:select_team')} onClick={handleTeamSelectClick} /> }
          <ListItem name={i18n.t('actions:log_out')} onClick={handleLogoutClick} />
        </List>
        <div className="Settings-image">
          { club.hasLogo && <img src={`${host}/club/${club._id}/logo`} alt="club logo"/> }
          <Icon name="mobileIcon" width={40} height={40} />
          <div className="Settings-name"><Text size={8} transform="uppercase" weight="bold">{i18n.t('common:younited')}</Text></div>
        </div>
        <Version/>
      </div>
    </div>
  )
}

function mapStateToProps (state) {
  const user = state.user.user || {}
  return {
    teams: user.teams || [],
    club: user.club,
    team: state.appState.team
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setTeam: team => dispatch(setAppState({ team }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
