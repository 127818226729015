const initialState = {
  team: null,
  canInstallPWA: false,
  deferredPrompt: null,
  pwaMessageClicked: false
}

function reducer (state = initialState, action) {
  switch (action.type) {
    case 'SET_APP_STATE': {
      return {
        ...state,
        ...action.newState
      }
    }
    default: return state
  }
}

export default reducer
