import host from '../host.js'
import get from './get.js'

function getUsers ({ teamId }) {
  const searchParams = new window.URLSearchParams()
  searchParams.set('team', teamId)
  const url = `${host}/user?${searchParams.toString()}`
  return get(url)
}

export default getUsers
