import React from 'react'
import i18n from '../../translations/i18n.js'
import Text from '../../components/Text/Text.js'
import Icon from '../../components/Icon/Icon.js'

const Arrow = () => {
  return (
    <svg width={102.039} height={177.901} viewBox="0 0 102.039 177.901">
      <path d="M.135 176.91s62.828-8.581 76.783-35.913-25.2-66.194-20.142-92.976S83.994 20.033 96.41 6.106" fill="none" stroke="#b2b1b1" strokeWidth={2} />
      <path d="M95.018 10.426l1.491 2.584 2.627-7.878.066-.225-8.36-1.711 1.471 2.55 3.977.846z" fill="#b2b1b1" />
    </svg>
  )
}

const AndroidOpera = () => {
  return (
    <div className="InstallAndroidOpera">
      <div className="InstallAndroidOpera-message">
        <Text size={16} weight="bold" color="red">{i18n.t('installation:install_the_app')}</Text>
        <Text size={14} color="black">{i18n.t('installation:tap_the_icon')}</Text>
        <div className="Install-preview-button"><Icon name="menu" width={20} height={20} /></div>
        <Text size={14} color="black">{i18n.t('installation:and_choose')}</Text>
        <div className="Install-preview-button"><Icon name="mobile" width={24} height={24} />&nbsp;{i18n.t('installation:homescreen')}</div>
        <div className="InstallAndroidOpera-arrow"><Arrow /></div>
      </div>
    </div>
  )
}

export default AndroidOpera
