import React from 'react'
import './Header.scss'
import Text from '../Text/Text.js'
import Icon from '../Icon/Icon.js'
import PropTypes from 'prop-types'

const Button = ({ children, onClick }) => <button className="Header-button" onClick={onClick}>{children}</button>

const leftButtons = {
  back: onClick => <Button onClick={onClick}><Icon name="back" width={12} height={12} fill="var(--black)" /></Button>,
  settings: onClick => <Button onClick={onClick}><Icon name="gear" width={19} height={19} fill="var(--black)" /></Button>
}

const rightButtons = {
  date: onClick => <Button onClick={onClick}><Icon name="today" width={19} height={19} fill="var(--black)" clubColor="var(--primary)" /></Button>,
  close: onClick => <Button onClick={onClick}><Icon name="cross" width={10} height={10} fill="var(--black)" /></Button>,
  search: onClick => <Button onClick={onClick}><Icon name="search" width={19} height={19} fill="var(--grey)" /></Button>,
  delete: onClick => <Button onClick={onClick}><Icon name="delete" width={19} height={19} fill="var(--black)" /></Button>,
  switch: onClick => <Button onClick={onClick}><Icon name="swap" width={19} height={19} fill="var(--black)" clubColor="var(--primary)" /></Button>,
  addPerson: onClick => <Button onClick={onClick}><Icon name="addPerson" width={22} height={22} fill="var(--black)" clubColor="var(--primary)" /></Button>
}

const Header = ({ title, subTitle, customTitle, leftAction, rightAction, onLeftAction, onRightAction }) => {
  const left = leftAction && leftButtons[leftAction](onLeftAction)
  const right = rightAction && rightButtons[rightAction](onRightAction)
  return (
    <div className="Header-root">
      <div className="Header-left">{left}</div>
      <div className="Header-title">
        { customTitle ||
          <>
            <Text size={16} weight="bold" color="primary" transform="uppercase">{title}</Text>
            { subTitle && <Text size={10} font="roboto" transform="uppercase">{subTitle}</Text> }
          </>
        }
      </div>
      <div className="Header-right">{right}</div>
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  customTitle: PropTypes.node,
  leftAction: PropTypes.oneOf(['back', 'settings']),
  rightAction: PropTypes.oneOf(['date', 'close', 'search', 'delete', 'switch', 'addPerson']),
  onLeftAction: PropTypes.func,
  onRightAction: PropTypes.func
}

export default Header
