import React, { useState, useReducer, useMemo, useEffect, useRef, useCallback } from 'react'
import './CreateActivity.scss'
import Header from '../../components/Header/Header.js'
import i18n from '../../translations/i18n.js'
import history from '../../history.js'
import Button, { ButtonRow } from '../../components/Button/Button.js'
import Input from '../../components/Input/Input.js'
import Select from '../../components/Select/Select.js'
import { getActivityItems } from '../../common/activityItems.js'
import createActivity from '../../connector/createActivity.js'
import updateActivity from '../../connector/updateActivity.js'
import updateActivityTeams from '../../connector/updateActivityTeams.js'
import updateActivityAttendees from '../../connector/updateActivityAttendees.js'
import updateActivitySupporters from '../../connector/updateActivitySupporters.js'
import { connect } from 'react-redux'
import { CreateSnackbarItem } from '../../components/Snackbar/Snackbar.js'
import AddAttendees from './AddAttendees.js'
import useUsers from '../../customHooks/useUsers.js'
import useActivity from '../../customHooks/useActivity.js'
import useClubTeams from '../../customHooks/useClubTeams.js'
import format from '../../format.js'
import AddTeams from './AddTeams.js'
import { isSameDay, isBefore, startOfTomorrow } from 'date-fns'
import removeFalsyFromArray from '../../removeFalsyFromArray.js'
import Modal from '../../components/Modal/Modal.js'
import Text from '../../components/Text/Text.js'
import Icon from '../../components/Icon/Icon.js'
import deleteActivity from '../../connector/deleteActivity.js'
import handleError from '../../handleError.js'
import sortArrayOfUsers from '../../sortArrayOfUsers.js'
import useScrollToInputOnResize from '../../customHooks/useScrollToInputOnResize.js'
import Loader from '../../components/Loader/Loader.js'

const initialState = {
  date: '',
  activityType: '',
  description: '',
  attendees: new Set(),
  supporters: new Set(),
  attendingTeams: new Set(),
  comment: '',
  updated: false
}

function reducer (state, action) {
  switch (action.type) {
    case 'setValue': {
      return {
        ...state,
        [action.name]: action.value,
        updated: true
      }
    }
    case 'setState': {
      return {
        ...action.state,
        attendees: state.attendees?.size ? state.attendees : action.state.attendees,
        supporters: state.supporters?.size ? state.supporters : action.state.supporters
      }
    }
    case 'setCurrentTeam': {
      return {
        ...state,
        attendingTeams: new Set([...state.attendingTeams, action.team])
      }
    }
    default: return state
  }
}

const CreateActivity = ({ user, team, edit, editId }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [missingValue, setMissingValue] = useState(false)
  const teamId = team && team._id
  const [membersError, membersLoading, members, reloadMembers] = useUsers(teamId)
  // TODO change membersLoading loading icon
  const [activityError, activityLoading, activity] = useActivity(editId)
  const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false)
  const [showBackWarning, setShowBackWarning] = useState(false)
  const [goBackConfirm, setGoBackConfirm] = useState(false)
  const [blockBackPress, setBlockBackPress] = useState(false)
  useScrollToInputOnResize()
  const [teamsError, teamsLoading, teams] = useClubTeams(user.club._id)
  const fixForIos = useRef(null)

  const { initialAttendees, initialSupporters } = useMemo(() => {
    if (activity && team) {
      const myTeam = activity.attendingTeams.find(attendingTeam => attendingTeam.team._id === team._id)
      const attendees = myTeam.attendees.map(attendee => attendee._id)
      const coaches = myTeam.coaches.map(coach => coach._id)
      const supporters = myTeam.supporters.map(supporter => supporter._id)
      return {
        initialAttendees: new Set([...attendees, ...coaches]),
        initialSupporters: new Set(supporters)
      }
    }
    return {
      initialAttendees: new Set(),
      initialSupporters: new Set()
    }
  }, [activity, team])

  useEffect(() => {
    if (edit && activity && members.length && team) {
      const myTeam = activity.attendingTeams.find(attendingTeam => attendingTeam.team._id === team._id)
      const attendees = myTeam.attendees.map(user => user._id)
      const coaches = myTeam.coaches.map(user => user._id)
      const supporters = new Set(myTeam.supporters.map(user => user._id))
      const allAttendees = new Set(removeFalsyFromArray([...attendees, ...coaches]))
      dispatch({
        type: 'setState',
        state: {
          date: format(new Date(activity.date), 'yyyy-MM-dd'),
          activityType: activity.activityType,
          description: activity.description || '',
          attendingTeams: new Set(activity.attendingTeams.map(team => (team.team || {})._id)),
          attendees: allAttendees,
          supporters: supporters,
          comment: activity.comment || ''
        }
      })
    }
  }, [edit, activity, members, team])

  useEffect(() => {
    if (team) {
      dispatch({
        type: 'setCurrentTeam',
        team: team._id
      })
    }
  }, [team])

  useEffect(() => {
    if (blockBackPress) {
      return history.block((location, action) => {
        return '__ignore'
      })
    }

    if (!state.updated) return
    return history.block((location, action) => {
      if (goBackConfirm || fixForIos.current) return ''
      setShowBackWarning(true)
      return '__ignore'
    })
  }, [goBackConfirm, state.updated, blockBackPress])

  if (membersError || teamsError) { // TODO handle error different
    CreateSnackbarItem({
      message: membersError.toString(),
      level: 'error'
    })
  }

  const sortedMembers = useMemo(() => {
    return sortArrayOfUsers(members)
  }, [members])

  function handleBackClick () {
    history.push('/activities')
  }

  const getNewArrayIfNewSetDifferentFromInitialSetElseUndefined = useCallback((newSet, initialSet) => {
    const set = new Set([...newSet])
    const isDifferentSize = set.size !== initialSet.size
    const initialSetLacksValues = [...set].some(id => !initialSet.has(id))
    const newSetLacksValues = [...initialSet].some(id => !set.has(id))
    if (isDifferentSize || initialSetLacksValues || newSetLacksValues) {
      return [...newSet]
    }
  }, [])

  async function handleSubmit (e) {
    e.preventDefault()

    if (!state.date || !state.activityType) {
      CreateSnackbarItem({
        message: i18n.t('errors:date_and_type_required'),
        level: 'error'
      })
      return setMissingValue(true)
    }

    let newActivity
    let invitedTeams = []
    if (edit) {
      newActivity = {}
      if (!isSameDay(new Date(state.date), new Date(activity.date))) newActivity.date = state.date
      if (state.activityType !== activity.activityType) newActivity.activityType = state.activityType
      if (state.description !== activity.description) newActivity.description = state.description
      if (state.comment !== activity.comment) newActivity.comment = state.comment
      if (!state.description && !activity.description) delete newActivity.description
      if (!state.comment && !activity.comment) delete newActivity.comment
    } else {
      newActivity = {
        date: state.date,
        activityType: state.activityType,
        attendingTeams: [...state.attendingTeams].map(id => ({
          team: id
        }))
      }
      if (state.description) newActivity.description = state.description
      if (state.comment) newActivity.comment = state.comment
      invitedTeams = [...state.attendingTeams].filter(id => id !== teamId)
    }

    let newTeams
    if (edit) {
      const stateTeams = state.attendingTeams
      const matchesBetweenOldAndNew = activity.attendingTeams.reduce((accumulator, currentValue) => {
        const team = (currentValue.team || {})
        if (stateTeams.has(team._id)) return accumulator + 1
        return accumulator
      }, 0)
      if (matchesBetweenOldAndNew !== stateTeams.size || matchesBetweenOldAndNew !== activity.attendingTeams.length) {
        newTeams = [...state.attendingTeams]
      }
      invitedTeams = (newTeams || []).filter(id => id !== teamId)
    }

    const newAttendees = getNewArrayIfNewSetDifferentFromInitialSetElseUndefined(
      state.attendees,
      initialAttendees
    )

    const newSupporters = getNewArrayIfNewSetDifferentFromInitialSetElseUndefined(
      state.supporters,
      initialSupporters
    )

    try {
      let updatedActivity = activity
      if (edit) {
        if (Object.getOwnPropertyNames(newActivity).length) {
          updatedActivity = await updateActivity(activity._id, newActivity).json()
        }
      } else {
        updatedActivity = await createActivity(newActivity).json()
      }

      if (newTeams) {
        await updateActivityTeams(updatedActivity._id, [...newTeams])
      }

      if (newAttendees) {
        await updateActivityAttendees(updatedActivity._id, team._id, newAttendees)
      }

      if (newSupporters) {
        await updateActivitySupporters(updatedActivity._id, team._id, newSupporters)
      }

      let message
      if (edit) {
        if (invitedTeams.length) {
          const teamNames = invitedTeams.map(id => (teams.find(team => team._id === id) || {}).name || '').join(', ')
          message = i18n.t('messages:activity_updated_and_team_invited', { team: teamNames })
        } else {
          message = i18n.t('messages:activity_updated')
        }
      } else {
        if (invitedTeams.length) {
          const teamNames = invitedTeams.map(id => (teams.find(team => team._id === id) || {}).name || '').join(', ')
          message = i18n.t('messages:activity_added_and_team_invited', { team: teamNames })
        } else {
          message = i18n.t('messages:activity_added')
        }
      }

      CreateSnackbarItem({
        message,
        level: 'success'
      })
      setGoBackConfirm(true)
      fixForIos.current = true
      history.goBack()
    } catch (error) {
      console.error(error)
      return CreateSnackbarItem({
        message: i18n.t('errors:something_went_wrong'),
        level: 'error'
      })
    }
  }

  function handleValueChange (name) {
    return e => {
      if (missingValue) setMissingValue(false)
      dispatch({
        type: 'setValue',
        name,
        value: e.target.value
      })
    }
  }

  function handleDateChange (e) {
    if (missingValue) setMissingValue(false)
    dispatch({
      type: 'setValue',
      name: 'date',
      value: e.target.value
    })
    if (!isBefore(new Date(e.target.value), startOfTomorrow())) {
      dispatch({
        type: 'setValue',
        name: 'attendees',
        value: new Set()
      })
    }
  }

  function handleDescriptionChange (e) {
    if (missingValue) setMissingValue(false)
    const newValue = e.target.value
    if (newValue.length > 30) {
      CreateSnackbarItem({
        message: i18n.t('messages:description_too_long'),
        level: 'error'
      })
    }
    dispatch({
      type: 'setValue',
      name: 'description',
      value: newValue.slice(0, 30)
    })
  }

  function handleSelectValueChange (name) {
    return value => {
      if (missingValue) setMissingValue(false)
      dispatch({
        type: 'setValue',
        name,
        value
      })
    }
  }

  function handleSetTeams (teams) {
    dispatch({
      type: 'setValue',
      name: 'attendingTeams',
      value: teams
    })
  }

  function handleDeleteClick (e) {
    setShowDeleteWarningModal(true)
  }

  function handleDeleteCancel () {
    setShowDeleteWarningModal(false)
  }

  async function handleDeleteConfirm () {
    try {
      await deleteActivity({ activityId: activity._id, teamId })
      setGoBackConfirm(true)
      fixForIos.current = true
      history.goBack()
    } catch (error) {
      console.error(error) // TODO handle error
      handleError(error)
      setShowDeleteWarningModal(false)
    }
  }

  function handleGoBackConfirm () {
    setGoBackConfirm(true)
    fixForIos.current = true
    history.goBack()
  }

  function enableBlockBackPress () {
    setBlockBackPress(true)
  }

  function disableBlockBackPress () {
    setBlockBackPress(false)
  }

  const activityItems = [...getActivityItems()]
  const selectedActivity = activityItems.find(item => item.id === state.activityType)
  const canAddAttendees = useMemo(() => {
    if (!state.date) return false
    return isBefore(new Date(state.date), startOfTomorrow())
  }, [state.date])

  const isYounitedActivity = activity ? activity.activityType === 'younited' : false
  if (!isYounitedActivity) activityItems.pop()

  return (
    <div>
      { activityError && <div>{activityError.toString()}</div> }
      <Modal active={showDeleteWarningModal} onClose={() => setShowDeleteWarningModal(false)}>
        <div className="CreateMember-modal">
          <div className="CreateMember-modal-warning"><Icon name="danger" fill="var(--red)" width={26} height={26} /></div>
          { initialAttendees.size
            ? <>
              <div className="CreateMember-modal-text"><Text size={14}>{i18n.t('messages:delete_activity_attendees_warning')}</Text></div>
              <Button onClick={handleDeleteCancel}>{i18n.t('actions:cancel')}</Button>
            </>
            : <>
              <div className="CreateMember-modal-text"><Text size={14}>{i18n.t('messages:delete_activity_warning')}</Text></div>
              <ButtonRow>
                <Button onClick={handleDeleteCancel}>{i18n.t('actions:cancel')}</Button>
                <Button onClick={handleDeleteConfirm}>{i18n.t('actions:delete_activity')}</Button>
              </ButtonRow>
            </>
          }
        </div>
      </Modal>
      <Modal active={showBackWarning} onClose={() => setShowBackWarning(false)}>
        <div className="CreateMember-modal">
          <div className="CreateMember-modal-warning"><Icon name="danger" fill="var(--red)" width={26} height={26} /></div>
          <div className="CreateActivity-back-modal"><Text size={14}>{i18n.t('messages:dont_save_activity_warning')}</Text></div>
          <ButtonRow>
            <Button onClick={handleGoBackConfirm}>{i18n.t('actions:delete')}</Button>
            <Button onClick={() => setShowBackWarning(false)}>{i18n.t('actions:keep_editing')}</Button>
          </ButtonRow>
        </div>
      </Modal>
      <Header
        title={i18n.t(edit ? 'actions:update_activity' : 'actions:add_activity')}
        subTitle={team.name}
        leftAction="back"
        onLeftAction={handleBackClick}
        rightAction={edit ? 'delete' : null}
        onRightAction={handleDeleteClick}
      />
      <form onSubmit={handleSubmit}>
        <div className="CreateActivity-body">
          { (activityLoading || membersLoading || teamsLoading) && <Loader fullSize={true} /> }
          <Input disabled={isYounitedActivity} label={i18n.t('actions:add_date')} value={state.date} onChange={handleDateChange} iconName="calendar" required={true} type="date" error={missingValue} />
          <Select disabled={isYounitedActivity} label={i18n.t('actions:add_activity_type')} value={activityItems.find(item => item.id === state.activityType)} onChange={handleSelectValueChange('activityType')} iconName={selectedActivity ? selectedActivity.iconName : 'ball'} iconColor={selectedActivity ? selectedActivity.iconColor : 'grey'} required={true} items={activityItems} error={missingValue} onFocus={enableBlockBackPress} onBlur={disableBlockBackPress} />
          { !shouldIHide(isYounitedActivity) && <AddTeams disabled={isYounitedActivity} teams={teams} selectedTeams={state.attendingTeams} setTeams={handleSetTeams} activity={activity} onFocus={enableBlockBackPress} onBlur={disableBlockBackPress} /> }
          { !shouldIHide(isYounitedActivity, state.description) && <Input disabled={isYounitedActivity} label={i18n.t('actions:add_description')} value={state.description} onChange={handleDescriptionChange} iconName="text" optional={true} /> }
          { !shouldIHide(isYounitedActivity, state.comment) && <Input disabled={isYounitedActivity} label={i18n.t('actions:add_comment')} value={state.comment} onChange={handleValueChange('comment')} type="textarea" iconName="talk" optional={true} /> }
          { !shouldIHide(!canAddAttendees) && <AddAttendees
            members={sortedMembers}
            attendees={state.attendees}
            onChangeAttendees={handleSelectValueChange('attendees')}
            supporters={state.supporters}
            onChangeSupporters={handleSelectValueChange('supporters')}
            reloadMembers={reloadMembers}
          /> }
        </div>
        { !shouldIHide(isYounitedActivity && !canAddAttendees) && <Button type="submit">{i18n.t('actions:save')}</Button> }
      </form>
    </div>
  )
}

function shouldIHide (disabled, value) {
  return disabled && !value
}

function mapStateToProps (state) {
  return {
    team: state.appState.team,
    user: state.user.user
  }
}

export default connect(mapStateToProps)(CreateActivity)
