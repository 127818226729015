import React from 'react'
import './Checkbox.scss'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon.js'
import removeFalsyFromArray from '../../removeFalsyFromArray.js'

const Checkbox = ({ onChange, checked, disabled }) => {
  const classes = removeFalsyFromArray([
    'Checkbox-root',
    checked && 'Checkbox-checked',
    disabled && 'Checkbox-disabled'
  ])
  return (
    <label className={classes.join(' ')}>
      <input type="checkbox" onChange={onChange} checked={checked} disabled={disabled} />
      <div className="Checkbox-box">
        <Icon name="check" width={16} height={16} fill="var(--primary-contrast)" />
      </div>
    </label>
  )
}

Checkbox.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool
}

export default Checkbox
