import i18n from '../translations/i18n.js'

let genderItems
function translateGenderItems () {
  genderItems = [
    {
      id: 'man',
      label: i18n.t('common:man')
    },
    {
      id: 'woman',
      label: i18n.t('common:woman')
    },
    {
      id: 'x',
      label: 'X'
    }
  ]
}
translateGenderItems()
i18n.on('languageChanged', () => {
  translateGenderItems()
})

function getGenderItems () {
  return genderItems
}

export default getGenderItems
