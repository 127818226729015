import React, { useState, useEffect } from 'react'
import './Snackbar.scss'
import SnackbarRow from './SnackbarRow.js'

let idCounter = 0
function getNextId () {
  return ++idCounter
}

const Snackbar = () => {
  const [items, setItems] = useState([])
  const [position, setPosition] = useState(56)

  useEffect(() => {
    function updatePosition (e) {
      let newPos = e.detail.position
      if (newPos !== 0 && !newPos) {
        newPos = 56
      }
      setPosition(newPos)
    }

    window.addEventListener('setSnackbarPosition', updatePosition)
    return () => window.removeEventListener('setSnackbarPosition', updatePosition)
  }, [])

  useEffect(() => {
    function reset (e) {
      setPosition(56)
      // setItems(new Set()) // enabling this is a bad idea, all items will disappear on every page change, including 'activity/member updated'
    }

    window.addEventListener('resetSnackbar', reset)
    return () => window.removeEventListener('resetSnackbar', reset)
  }, [])

  useEffect(() => {
    function handleEvent (e) {
      const detail = e.detail
      const id = getNextId()
      const newItem = {
        id,
        message: detail.message,
        level: detail.level,
        action: detail.action,
        onAction: detail.onAction,
        expiresAt: new Date().getTime() + (detail.duration || 4000),
        show: false,
        removed: false
      }
      const newItems = [...items, newItem]
      setItems(newItems)
    }
    window.addEventListener('addSnackbarItem', handleEvent)
    return () => window.removeEventListener('addSnackbarItem', handleEvent)
  }, [items])

  useEffect(() => {
    // more than 1, hide first
    if (items.length > 1 && items[0].show) {
      const newItems = [...items]
      newItems[0] = {
        ...newItems[0],
        show: false,
        removed: true
      }
      setItems(newItems)
    }
  }, [items])

  useEffect(() => {
    // only 1, show first
    if (items.length === 1 && !items[0].show && !items[0].removed) {
      const newItems = [...items]
      newItems[0] = {
        ...newItems[0],
        show: true
      }
      setItems(newItems)
    }
  }, [items])

  useEffect(() => {
    // reset in case too many events broke the snackbar, which happens, it's a bug
    const timeout = setTimeout(() => {
      if (items.length) {
        setItems([])
      }
    }, 5500)
    return () => { clearTimeout(timeout) }
  }, [items])

  function handleItemClose () {
    const newItems = [...items]
    newItems[0] = {
      ...newItems[0],
      show: false,
      removed: true
    }
    setItems(newItems)
  }

  function handleCloseDone () {
    const newItems = items.slice(1)
    setItems(newItems)
  }

  return (
    <div className="Snackbar-root" style={{ marginBottom: position }}>
      {items.map(item => <SnackbarRow key={item.id} {...item} onClose={handleItemClose} onCloseDone={handleCloseDone} />)}
    </div>
  )
}

function CreateSnackbarItem ({ message, level, action, onAction, duration }) {
  const event = new window.CustomEvent('addSnackbarItem', {
    detail: {
      message,
      level,
      action,
      onAction,
      duration
    }
  })
  window.dispatchEvent(event)
}

function setSnackbarPosition (position) {
  window.dispatchEvent(new window.CustomEvent('setSnackbarPosition', { detail: { position } }))
}

function resetSnackbar () {
  window.dispatchEvent(new window.CustomEvent('resetSnackbar'))
}

export default Snackbar
export { CreateSnackbarItem, resetSnackbar, setSnackbarPosition }
