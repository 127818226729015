import React from 'react'
import i18n from '../../translations/i18n.js'
import Text from '../../components/Text/Text.js'
import Icon from '../../components/Icon/Icon.js'
import history from '../../history.js'

const Arrow = () => {
  return (
    <svg width={138.205} height={231.443} viewBox="0 0 138.205 231.443">
      <path d="M2.983 225.057H0l5.509 6.214.162.169 5.662-6.385H8.389l-2.721 3.021z" fill="#b2b1b1" />
      <path d="M37.159.999s102.715 2.03 99.991 87.773-131.654 70.539-131.6 139.8" fill="none" stroke="#b2b1b1" strokeWidth={2} />
    </svg>
  )
}

const Share = () => {
  return (
    <svg width={22} height={28.87} viewBox="0 0 22 28.87">
      <path d="M17.875 9.619h1.375a2.75 2.75 0 012.75 2.75V26.12a2.75 2.75 0 01-2.75 2.75H2.75A2.75 2.75 0 010 26.12V12.369a2.75 2.75 0 012.75-2.75h1.375a1.375 1.375 0 010 2.75H2.75V26.12h16.5V12.369h-1.375a1.375 1.375 0 010-2.75zm-5.5-4.924v11.8a1.375 1.375 0 01-2.751 0v-11.8L8.082 6.237a1.375 1.375 0 11-1.944-1.945l3.894-3.89a1.376 1.376 0 011.944 0l3.889 3.889a1.375 1.375 0 01-1.944 1.945z" />
    </svg>
  )
}

const Add = () => {
  return (
    <svg width={28.099} height={28.098} viewBox="0 0 28.099 28.098">
      <path d="M20.585 12.743h-5.228V7.515a1.307 1.307 0 10-2.614 0v5.228H7.515a1.307 1.307 0 100 2.614h5.228v5.228a1.307 1.307 0 102.614 0v-5.228h5.228a1.307 1.307 0 100-2.614z" />
      <path d="M28.099 4.7a4.727 4.727 0 00-4.7-4.7H4.704A4.727 4.727 0 00-.001 4.7v18.694a4.706 4.706 0 004.7 4.7h18.695a4.706 4.706 0 004.7-4.7zm-2.614 18.69a2.1 2.1 0 01-2.086 2.095H4.704a2.1 2.1 0 01-2.091-2.091V4.7a2.1 2.1 0 012.091-2.091h18.689A2.1 2.1 0 0125.484 4.7z" />
    </svg>
  )
}

const IOSSafari = () => {
  function handleCloseClick () {
    history.goBack()
  }

  return (
    <div className="Install-root">
      <button onClick={handleCloseClick} className="Install-close"><Icon name="cross" width={12} height={12} /></button>
      <div className="Install-logo-top">
        <Icon name="mobileIcon" width={44} height={44} />
        <Text transform="uppercase" weight="bold" size={8}>Younited</Text>
      </div>
      <div className="Install-content">
        <div className="InstallIOSSafari">
          <div className="InstallIOSSafari-message">
            <Text size={16} weight="bold" color="red">{i18n.t('installation:install_the_app')}</Text>
            <Text size={14} color="black">{i18n.t('installation:tap_the_icon')}</Text>
            <div className="Install-preview-button"><Share /></div>
            <Text size={14} color="black">{i18n.t('installation:and_choose')}</Text>
            <div className="Install-preview-button">{i18n.t('installation:safari_pwa_message')}&nbsp;<Add /></div>
            <div className="InstallIOSSafari-arrow"><Arrow /></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IOSSafari
