import React, { useState, useReducer, useEffect } from 'react'
import './EditUser.scss'
import Header from '../../components/Header/Header.js'
import i18n from '../../translations/i18n.js'
import Input from '../../components/Input/Input.js'
import Select from '../../components/Select/Select.js'
import getGenderItems from '../../common/genderItems.js'
import getSizeItems from '../../common/sizeItems.js'
import history from '../../history.js'
import { connect } from 'react-redux'
import Button, { ButtonRow } from '../../components/Button/Button.js'
import PhoneInput from '../../components/PhoneInput/PhoneInput.js'
import updateUser from '../../connector/updateUser.js'
import { CreateSnackbarItem } from '../../components/Snackbar/Snackbar.js'
import useScrollToInputOnResize from '../../customHooks/useScrollToInputOnResize.js'
import Modal from '../../components/Modal/Modal.js'
import Icon from '../../components/Icon/Icon.js'
import Text from '../../components/Text/Text.js'
import { setUser } from '../../redux/user/actions.js'
import ProfilePictureInput from '../../components/ProfilePictureInput/ProfilePictureInput'

const initialState = {
  gender: '',
  birthYear: '',
  shirtSize: '',
  shoeSize: '',
  phoneNumber: '',
  updated: false
}

function reducer (state, action) {
  switch (action.type) {
    case 'setValue': {
      return {
        ...state,
        [action.name]: action.value,
        updated: true
      }
    }
    case 'setState': {
      return action.state
    }
    default: return state
  }
}

const EditUser = ({ user, setUser }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [goBackConfirm, setGoBackConfirm] = useState(false)
  const [showBackWarning, setShowBackWarning] = useState(false)
  const [blockBackPress, setBlockBackPress] = useState(false)
  useScrollToInputOnResize()
  const [invalidName, setInvalidName] = useState(false)
  const [invalidBirthYear, setInvalidBirthYear] = useState(false)
  const [invalidShoesize, setInvalidShoesize] = useState(false)

  useEffect(() => {
    if (user) {
      dispatch({
        type: 'setState',
        state: {
          name: user.name || '',
          profilePicture: user.profilePicture || null,
          clearProfilePicture: false,
          gender: user.gender || '',
          birthYear: user.birthYear || '',
          shirtSize: (user.shirtSize || '').toLowerCase(),
          shoeSize: user.shoeSize || '',
          phoneNumber: user.phoneNumber || '',
          comment: user.comment || ''
        }
      })
    }
  }, [user])

  useEffect(() => {
    if (state.name && typeof state.name === 'string' && state.name.length && invalidName) {
      setInvalidName(false)
    }
  }, [state.name, invalidName])

  useEffect(() => {
    const size = state.shoeSize
    if (size && (size < 1 || size > 100)) {
      if (!invalidShoesize) setInvalidShoesize(true)
    } else {
      if (invalidShoesize) setInvalidShoesize(false)
    }
  }, [state.shoeSize, invalidShoesize])

  useEffect(() => {
    if (blockBackPress) {
      const unblock = history.block((location, action) => {
        return '__ignore'
      })
      return unblock
    }

    if (!state.updated) return
    const unblock = history.block((location, action) => {
      if (goBackConfirm) return ''
      setShowBackWarning(true)
      return '__ignore'
    })
    return unblock
  }, [goBackConfirm, state.updated, blockBackPress])

  function handleInputChange (name) {
    return e => {
      dispatch({
        type: 'setValue',
        name,
        value: e.target.value
      })
    }
  }

  function handleSelectChange (name) {
    return value => {
      dispatch({
        type: 'setValue',
        name,
        value
      })
    }
  }

  function handleChangeProfilePicture (value) {
    handleSelectChange('profilePicture')(value)
    handleSelectChange('clearProfilePicture')(false)
  }

  function handleClearProfilePicture () {
    handleSelectChange('profilePicture')(null)
    handleSelectChange('clearProfilePicture')(true)
  }

  function handleBackClick () {
    history.goBack()
  }

  async function handleSubmit (e) {
    e.preventDefault()

    if (!(state.name && typeof state.name === 'string' && state.name.length)) {
      if (!invalidName) setInvalidName(true)
      return
    }

    if (typeof state?.birthYear !== 'string' || isNaN(state?.birthYear) || state?.birthYear?.length !== 4) {
      setInvalidBirthYear(true)
      return
    }

    if (state.shoeSize) {
      const size = parseInt(state.shoeSize)
      if (size < 1 || size > 100) {
        CreateSnackbarItem({
          message: i18n.t('errors:invalid_shoesize'),
          level: 'error'
        })
        return setInvalidShoesize(true)
      }
    }

    const newUser = {
      name: state.name,
      gender: state.gender,
      birthYear: parseInt(state.birthYear),
      shirtSize: state.shirtSize,
      shoeSize: parseInt(state.shoeSize),
      phoneNumber: state.phoneNumber,
      comment: state.comment,
      clearProfilePicture: state.clearProfilePicture
    }

    if (state.profilePicture instanceof File) {
      newUser.profilePicture = state.profilePicture
    }

    try {
      const newUserObject = await updateUser(user._id, newUser).json()
      setUser(newUserObject)
    } catch (error) {
      console.error(error) // TODO handle error
    }

    CreateSnackbarItem({
      message: i18n.t('messages:info_updated'),
      level: 'success'
    })
    setGoBackConfirm(true)
    history.goBack()
  }

  function handleGoBackConfirm () {
    setGoBackConfirm(true)
    history.goBack()
  }

  function enableBlockBackPress () {
    setBlockBackPress(true)
  }

  function disableBlockBackPress () {
    setBlockBackPress(false)
  }

  function validateBirthYear () {
    if (state.birthYear) {
      let newValue
      if (state.birthYear < 1950) {
        newValue = 1950
      } else if (state.birthYear > new Date().getFullYear()) {
        newValue = new Date().getFullYear()
      }
      if (newValue) {
        dispatch({
          type: 'setValue',
          name: 'birthYear',
          value: newValue
        })
      }
    }
  }

  const genderItems = getGenderItems()
  const sizeItems = getSizeItems()

  return (
    <div>
      <Modal active={showBackWarning} onClose={() => setShowBackWarning(false)}>
        <div className="CreateMember-modal">
          <div className="CreateMember-modal-warning"><Icon name="danger" fill="var(--red)" width={26} height={26} /></div>
          <div className="CreateActivity-back-modal"><Text size={14}>{i18n.t('messages:dont_save_warning')}</Text></div>
          <ButtonRow>
            <Button onClick={handleGoBackConfirm}>{i18n.t('actions:delete')}</Button>
            <Button onClick={() => setShowBackWarning(false)}>{i18n.t('actions:keep_editing')}</Button>
          </ButtonRow>
        </div>
      </Modal>
      <Header
        customTitle={
          <div className={['CreateMember-title', invalidName && 'CreateMember-title-error'].join(' ')}>
            <input value={state.name || ''}
                   onChange={handleInputChange('name')}
                   placeholder={i18n.t('common:your_name')}
            />
            <div className="CreateMember-title-icon"><Icon name="danger" fill="var(--red)" width={24} height={24} /></div>
          </div>
        }
        leftAction="back"
        onLeftAction={handleBackClick}
      />
      <form onSubmit={handleSubmit}>
        <div className="EditUser-body">
          <ProfilePictureInput
            label={i18n.t('actions:set_profile_picture')}
            value={state.profilePicture}
            onChange={handleChangeProfilePicture}
            onClear={handleClearProfilePicture}
            onFocus={enableBlockBackPress}
            onBlur={disableBlockBackPress}
          />
          <Select
            iconName="gender"
            value={genderItems.find(item => item.id === state.gender)}
            onChange={handleSelectChange('gender')}
            label={i18n.t('actions:add_gender')}
            items={genderItems}
            onFocus={enableBlockBackPress}
            onBlur={disableBlockBackPress}
          />
          <Input
            iconName="present"
            required={true}
            error={invalidBirthYear}
            value={state.birthYear}
            onChange={handleInputChange('birthYear')}
            type="number"
            label={i18n.t('actions:add_birthyear')}
            onBlur={validateBirthYear}
          />
          <Select
            iconName="clothing"
            label={i18n.t('actions:add_tshirt_size')}
            items={sizeItems}
            value={sizeItems.find(item => item.id === state.shirtSize)}
            onChange={handleSelectChange('shirtSize')}
            onFocus={enableBlockBackPress}
            onBlur={disableBlockBackPress}
          />
          <Input
            iconName="shoes"
            value={state.shoeSize}
            onChange={handleInputChange('shoeSize')}
            type="number"
            label={i18n.t('actions:add_shoesize')}
            error={invalidShoesize}
          />
          <PhoneInput
            iconName="phone"
            label={i18n.t('actions:add_mobile')}
            value={state.phoneNumber}
            onChange={handleSelectChange('phoneNumber')}
            onFocus={enableBlockBackPress}
            onBlur={disableBlockBackPress}
          />
          <Input
            iconName="talk"
            label={i18n.t('actions:add_comment')}
            value={state.comment}
            type="textarea"
            onChange={handleInputChange('comment')}
          />
        </div>
        <Button type="submit">{i18n.t('actions:save')}</Button>
      </form>
    </div>
  )
}

function mapStateToProps (state) {
  return {
    user: state.user.user
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setUser: user => dispatch(setUser(user))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUser)
