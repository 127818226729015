import React from 'react'
import i18n from '../../translations/i18n.js'

const NotFound = () => {
  return (
    <div>{i18n.t('errors:page_not_found')}</div>
  )
}

export default NotFound
