import getRoleIcon from '../../getRoleIcon'
import { ListItem } from '../../components/List/List'
import neutralizeEvent from '../../neutralizeEvent'
import Checkbox from '../../components/Checkbox/Checkbox'
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture'
import React from 'react'

const AttendeeRow = ({
  user,
  checkedAsAttendee,
  checkedAsSupporter,
  onAttendeeToggle,
  onSupporterToggle,
  onToggle
}) => {
  const iconName = getRoleIcon(user.role)
  return (
    <div onClick={onToggle}>
      <ListItem
        key={user._id}
        beforeIcon={
          <div className="AttendeesList-checkbox" onClick={neutralizeEvent}>
            <Checkbox checked={checkedAsAttendee} onChange={onAttendeeToggle}/>
          </div>
        }
        afterName={
          <div className="AttendeesList-checkbox" onClick={neutralizeEvent}>
            <Checkbox checked={checkedAsSupporter} onChange={onSupporterToggle}/>
          </div>
        }
        iconName={iconName}
        beforeName={<ProfilePicture value={user.profilePicture}/>}
        name={user.name}
      />
    </div>
  )
}

export default AttendeeRow
