import React from 'react'
import './Loader.scss'
import PropTypes from 'prop-types'
import removeFalsyFromArray from '../../removeFalsyFromArray.js'

const Loader = ({ fullSize }) => {
  const classes = removeFalsyFromArray([
    'Loader',
    fullSize && 'Loader-fullSize'
  ])
  return (
    <div className={classes.join(' ')}>
      <div className="loadingio-spinner-dual-ball-j2ns4s75njr">
        <div className="ldio-asruc5cikdd">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  )
}

Loader.propTypes = {
  fullSize: PropTypes.bool
}

export default Loader
