import React from 'react'
import PropTypes from 'prop-types'

const icons = {
  eye: props => <svg width="1em" height="1em" viewBox="0 0 16 10.353" {...props}>
    <path d="M8 0C3.295 0 0 5.177 0 5.177s2.4 5.177 8 5.177c5.647 0 8-5.177 8-5.177S12.753 0 8 0zm0 8.47a3.294 3.294 0 113.294-3.294A3.273 3.273 0 018 8.47z" />
    <path d="M9.411 5.177a1.412 1.412 0 11-1.412-1.412 1.411 1.411 0 011.412 1.412" />
  </svg>,
  today: ({ clubColor, ...props }) => <svg width="1em" height="1em" viewBox="0 0 19.062 19" {...props}>
    <path d="M16.993 2.189h-2.19V.843A.848.848 0 0013.96 0h-1.025a.848.848 0 00-.843.843v1.326h-5.1V.843a.848.848 0 00-.846-.845H5.122a.848.848 0 00-.843.843v1.328h-2.19A2.087 2.087 0 00-.002 4.257v12.654a2.087 2.087 0 002.091 2.087h14.884a2.087 2.087 0 002.089-2.089V4.257a2.065 2.065 0 00-2.064-2.068zm.241 14.081a.9.9 0 01-.9.9H2.751a.9.9 0 01-.9-.9v-9.2h15.362v9.2z" />
    <path d="M11.971 12.111a2.43 2.43 0 11-2.43-2.429 2.43 2.43 0 012.43 2.429" fill={clubColor} />
  </svg>,
  key: props => <svg width="1em" height="1em" viewBox="0 0 16 17.976" {...props}>
    <path d="M14.997 12.234l-.305-.27a1.578 1.578 0 00-.83-.446c-.312.038-.358-.049-.372-.307-.03-.625-1.083-.02-1.083-.02-.434.215-.651.095-.638-.532l.032-.528c.035-.561-.079-.69-.561-.622-.415.058-.6.06-.534-.474.038-.3.189-.403-.456-1.011l-.423-.378a4.594 4.594 0 10-2.38 2.746l5.14 4.56a2.144 2.144 0 001.223.467l1.331.01a.6.6 0 00.619-.688l-.155-1.351a2.112 2.112 0 00-.6-1.157zM6.2 4.628L3.93 7.19c-.255.287-.45.21-.677-.142a2.129 2.129 0 01.296-2.485 2.058 2.058 0 012.43-.588c.414.193.477.368.221.655z" />
  </svg>,
  repeat: props => <svg width="1em" height="1em" viewBox="0 0 15.997 16" {...props}>
    <path d="M8.014 16a8.018 8.018 0 007.984-7.264A.673.673 0 0015.33 8h-1.346a.661.661 0 00-.659.586 5.343 5.343 0 11-5.311-5.918 5.283 5.283 0 012.482.622.12.12 0 01.061.1.121.121 0 01-.049.1l-1.293.942a.213.213 0 00.064.4l5.1.744a.27.27 0 00.307-.306L13.945.172a.214.214 0 00-.397-.059l-.892 1.257a.112.112 0 01-.156.027A7.948 7.948 0 007.761.005a8.079 8.079 0 00-7.755 7.7 8.014 8.014 0 008.009 8.3z" />
  </svg>,
  search: props => <svg width="1em" height="1em" viewBox="0 0 18.998 19" {...props}>
    <path d="M18.996 16.851l-5.06-5.059a7.6 7.6 0 10-2.149 2.149L16.849 19zM2.153 7.6a5.447 5.447 0 115.443 5.448A5.446 5.446 0 012.153 7.6z" />
  </svg>,
  asterisk: props => <svg width="1em" height="1em" viewBox="0 0 7.282 6.108" {...props}>
    <path d="M.634 3.071L0 4.17l3.454-.707.098-.023L2.404.005l-.623 1.079.533 1.641zM5.508 1.098L4.875 0 3.76 3.345l-.029.096 3.548.723-.623-1.079-1.688-.359zM4.82 6.108h1.267L3.748 3.47l-.069-.073-2.4 2.711h1.246L3.68 4.826z" />
  </svg>,
  talk: props => <svg width="1em" height="1em" viewBox="0 0 15.932 13.837" {...props}>
    <path d="M2.082 0H13.85a2.09 2.09 0 012.082 2.082v6.16a2.09 2.09 0 01-2.082 2.082H6.904l-2.361 3.315a.474.474 0 01-.859-.274v-3.041h-1.6a2.09 2.09 0 01-2.08-2.082v-6.16A2.09 2.09 0 012.081 0z" />
  </svg>,
  phone: props => <svg width="1em" height="1em" viewBox="0 0 16 19.212" {...props}>
    <path d="M15.057 13.6l-2.112-1.578a1.684 1.684 0 00-2.373.364l-.619.874a.478.478 0 01-.602.126A14.465 14.465 0 014.688 8.46a.46.46 0 01.15-.603l.879-.58a1.7 1.7 0 00.475-2.362L4.707 2.772a1.307 1.307 0 00-1.963-.22C.555 4.629-.195 8.064 4.654 13.157s8.323 4.489 10.51 2.413a1.29 1.29 0 00-.114-1.967z" />
  </svg>,
  soccer: props => <svg width="1em" height="1em" viewBox="0 0 15.274 16" {...props}>
    <path d="M1.212 9.133l2.449.56 1.12-1.516a.152.152 0 01.269.056 2.879 2.879 0 00.542 1.115l.374.475a.279.279 0 01-.023.37l-1.2 1.2a.95.95 0 01-.937.307L.748 11a.962.962 0 01-.7-1.242.988.988 0 011.162-.624zM12.574.046a1.778 1.778 0 101.1.787 1.777 1.777 0 00-1.1-.787zm2.41 8.035a.789.789 0 00.291-.554.816.816 0 00-.725-.849l-2.387-.312-.661-1.633a2.058 2.058 0 00-.743-.924 16.341 16.341 0 00-4.257-2.086l-.242-.078a.782.782 0 00-.793.187L3.413 3.86a.816.816 0 00-.067 1.105.791.791 0 001.153.044l1.726-1.705 1.411.737-1.445 2.207a2.238 2.238 0 00.173 2.53l2.079 2.65-1.461 3.169a.989.989 0 00.228 1.17.972.972 0 00.425.208.935.935 0 00.111.018.966.966 0 00.98-.555l1.706-3.741a.946.946 0 00-.1-.98L9.011 8.24l1.22-2.136.615 1.26a.788.788 0 00.564.49l.075.013 2.893.376a.77.77 0 00.6-.163zm-2.269 4.408a1.778 1.778 0 101.108.788 1.778 1.778 0 00-1.108-.788z" />
  </svg>,
  shield: props => <svg width="1em" height="1em" viewBox="0 0 13.16 16" {...props}>
    <path d="M6.524.005a.537.537 0 00-.15.039L.819 2.351a.535.535 0 00-.306.339 11.944 11.944 0 00.239 7.639 9.5 9.5 0 005.644 5.639.529.529 0 00.367 0 9.5 9.5 0 005.649-5.64 11.944 11.944 0 00.239-7.639.535.535 0 00-.306-.339C6.703.005 6.612-.008 6.524.005z" />
  </svg>,
  heart: props => <svg width="1em" height="1em" viewBox="0 0 16 13.659" {...props}>
    <path d="M1.159 1.166a3.971 3.971 0 000 5.606l6.839 6.888 6.845-6.88a3.97 3.97 0 000-5.606 3.922 3.922 0 00-5.578 0L8.005 2.44 6.737 1.166a3.922 3.922 0 00-5.578 0z" />
  </svg>,
  delete: props => <svg width="1em" height="1em" viewBox="0 0 16.652 19" {...props}>
    <path d="M1.639 5.156v11.5a2.353 2.353 0 002.345 2.347h8.676a2.353 2.353 0 002.35-2.347v-11.5zm3.985 11.731H4.456V7.266h1.173zm3.284 0H7.739V7.266h1.173zm3.284 0h-1.169V7.266h1.173zM10.085 1.643V0H6.566v1.643h-6.57V3.99h16.656V1.643z" />
  </svg>,
  shoes: props => <svg width="1em" height="1em" viewBox="0 0 16 16.409" {...props}>
    <path d="M10.974-.002l3.957 5.434-11.66 8.392H0a2.438 2.438 0 011.692-2.426A4.6 4.6 0 004.014 9.71l1.213 1.161a.4.4 0 00.633 0 .45.45 0 000-.686L4.592 8.864a3.8 3.8 0 00.475-1.056l1.53 1.478a.449.449 0 00.686 0 .552.552 0 000-.686L5.329 6.698a7.025 7.025 0 00.163-1.474L7.18 6.859a.481.481 0 00.633 0 .45.45 0 000-.686L5.541 3.852a11.459 11.459 0 01-.049-1.372l5.483-2.482zm4.537 6.226a1.857 1.857 0 01.475.9c.106.475-.422 1.266-.422 1.266L4.592 16.304l-3.482.106a2.143 2.143 0 01-1-1.583l3.324-.053.264-.053 11.813-8.494z" />
  </svg>,
  email: props => <svg width="1em" height="1em" viewBox="0 0 16 10.537" {...props}>
    <path d="M.933 0L8 5.689 15.067 0zM0 .744v9.793h16V.744L8.366 6.89a.585.585 0 01-.732 0z" />
  </svg>,
  calendar: props => <svg width="1em" height="1em" viewBox="0 0 16 18.286" {...props}>
    <path d="M15.571 5.714H.429A.43.43 0 010 5.286V4a1.715 1.715 0 011.714-1.714h1.715V.429A.43.43 0 013.857 0h1.429a.43.43 0 01.429.429v1.857h4.571V.429A.43.43 0 0110.714 0h1.429a.43.43 0 01.429.429v1.857h1.714A1.715 1.715 0 0116 4v1.286a.43.43 0 01-.429.428zM.429 6.857h15.142a.43.43 0 01.429.429v9.286a1.715 1.715 0 01-1.714 1.714H1.714A1.715 1.715 0 010 16.571V7.286a.43.43 0 01.429-.429z" />
  </svg>,
  clothing: props => <svg width="1em" height="1em" viewBox="0 0 16 14" {...props}>
    <path d="M8 1.667A1.668 1.668 0 009.667 0H6.333A1.668 1.668 0 008 1.667z" />
    <path d="M15.902 3.437l-3.23-3.1-.25-.24a.352.352 0 00-.233-.093h-1.855a2.333 2.333 0 01-4.666 0H3.813a.352.352 0 00-.233.093l-.25.24-3.228 3.1a.334.334 0 000 .476l2.037 1.99a.333.333 0 00.466 0l1.397-1.236v9a.334.334 0 00.333.333h7.334a.334.334 0 00.333-.333v-9l1.4 1.237a.333.333 0 00.466 0l2.037-1.99a.334.334 0 000-.476z" />
  </svg>,
  gender: props => <svg width="1em" height="1em" viewBox="0 0 16 15.483" {...props}>
    <path d="M12.062 0a.663.663 0 100 1.325h1.677L12.39 2.674a4.732 4.732 0 00-5.252-.292 4.729 4.729 0 10-3.072 8.752v1.041h-1.32a.663.663 0 000 1.325h1.32v1.321a.663.663 0 001.325 0V13.5h1.32a.663.663 0 000-1.325h-1.32v-1.041a4.681 4.681 0 001.747-.612 4.73 4.73 0 006.188-6.913l1.349-1.349v1.677a.662.662 0 001.325 0V.661A.663.663 0 0015.336 0zM4.73 3.049a3.393 3.393 0 011.292.254 4.726 4.726 0 000 6.3A3.4 3.4 0 114.73 3.049zm4.818 0a3.4 3.4 0 11-1.293 6.554 4.728 4.728 0 000-6.3 3.4 3.4 0 011.294-.254zm-2.41 1a3.4 3.4 0 010 4.808 3.4 3.4 0 010-4.808z" />
  </svg>,
  present: props => <svg width="1em" height="1em" viewBox="0 0 16 18.177" {...props}>
    <path d="M7.563 10.167v7.954c-1.9 0-3.781.056-5.63-.056-.812 0-.952-.532-.952-1.092-.028-2.241 0-4.481 0-6.806h6.582zM8.656 18.177v-8.01h6.555v6.834c0 .813-.644 1.176-1.4 1.176H8.656zM8.096 3.053c.617-.756 1.12-1.484 1.736-2.157a2.3 2.3 0 012.969-.56 2.423 2.423 0 011.177 2.633 2.334 2.334 0 01-2.437 1.821q-3.487.043-6.974 0a2.241 2.241 0 01-2.353-1.876A2.369 2.369 0 013.334.365a2.224 2.224 0 012.913.42c.645.7 1.2 1.484 1.849 2.268zm-.728.756a15.2 15.2 0 00-1.849-2.268 1.884 1.884 0 00-1.624-.28 1.32 1.32 0 00-.728 1.428c.084.476.756.924 1.232 1.036a12.539 12.539 0 002.969.084zm1.457.028a10.864 10.864 0 002.941-.084 1.494 1.494 0 001.261-1.261 1.327 1.327 0 00-.7-1.232 1.8 1.8 0 00-1.6.252 13.822 13.822 0 00-1.9 2.325zM7.564 5.853v3.222H2.241C.505 9.075 0 9.275 0 7.254c0-.588.112-1.372 1.176-1.4 2.073-.056 4.146 0 6.387 0zM8.656 9.074V5.857c2.241 0 4.425-.028 6.61.028.84.056.728 1.12.728 1.176 0 2.268-.252 2.045-2.185 2.017H8.656z" />
  </svg>,
  text: props => <svg width="1em" height="1em" viewBox="0 0 16 12.444" {...props}>
    <path d="M0 0h16v1.933H0zM0 5.256h16v1.933H0zM0 10.511h9.667v1.933H0z" />
  </svg>,
  person: props => <svg width="1em" height="1em" viewBox="0 0 16 15.968" {...props}>
    <path d="M15.75 13.641a9.55 9.55 0 00-1.1-1.325 9.435 9.435 0 00-4.287-2.463 4.437 4.437 0 002.066-3.74V4.34a4.433 4.433 0 00-8.865 0v1.773a4.434 4.434 0 002.06 3.739 9.422 9.422 0 00-4.276 2.461 9.546 9.546 0 00-1.064 1.27 1.506 1.506 0 001.218 2.386h12.993a1.5 1.5 0 001.255-2.327z" />
  </svg>,
  danger: props => <svg width="1em" height="1em" viewBox="0 0 4.408 23.978" {...props}>
    <path d="M2.204 23.978a2.2 2.2 0 10-1.559-.645 2.2 2.2 0 001.559.645zM0 0h4.408v15.427H0z" />
  </svg>,
  gear: props => <svg width="1em" height="1em" viewBox="0 0 19 19" {...props}>
    <path d="M18.946 8.486a9.479 9.479 0 010 2.029l-2.1.924a7.578 7.578 0 01-.783 1.887l.83 2.135a9.607 9.607 0 01-1.432 1.439l-2.135-.829a7.59 7.59 0 01-1.887.783l-.924 2.1a9.487 9.487 0 01-2.029 0l-.924-2.1a7.578 7.578 0 01-1.887-.783l-2.134.829A9.607 9.607 0 012.1 15.46l.829-2.135a7.528 7.528 0 01-.783-1.887l-2.1-.924a9.479 9.479 0 010-2.029l2.1-.923a7.583 7.583 0 01.783-1.888L2.1 3.54A9.53 9.53 0 013.541 2.1l2.135.829a7.551 7.551 0 011.888-.783l.924-2.1a9.471 9.471 0 012.028 0l.924 2.1a7.534 7.534 0 011.887.783L15.46 2.1a9.569 9.569 0 011.44 1.44l-.829 2.135a7.577 7.577 0 01.783 1.887zM9.5 6.142A3.358 3.358 0 1012.858 9.5 3.358 3.358 0 009.5 6.142z" />
  </svg>,
  check: props => <svg width="1em" height="1em" viewBox="0 0 21 16.099" {...props}>
    <path d="M0 8.867l2.333-3.035L8.4 10.967 18.2 0 21 2.567 8.633 16.099z" />
  </svg>,
  back: props => <svg width="1em" height="1em" viewBox="0 0 11.911 11.336" {...props}>
    <path d="M11.911 2.983V0L5.697 5.509l-.169.162 6.385 5.662V8.389L8.892 5.668z" />
    <path d="M6.385 2.983V0L.171 5.509l-.169.162 6.385 5.662V8.389L3.366 5.668z" />
  </svg>,
  plus: props => <svg width="1em" height="1em" viewBox="0 0 11.463 11.463" {...props}>
    <path d="M4.3 0v4.3H0v2.866h4.3v4.3h2.866v-4.3h4.3V4.3h-4.3V0z" />
  </svg>,
  cup: props => <svg width="1em" height="1em" viewBox="0 0 16 18.364" {...props}>
    <path d="M3.262 18.364v-.6c0-1.63 1.787-3.022 3.961-3.022h.011l-.009-4.226A4.334 4.334 0 015.049 9.4a6.1 6.1 0 01-.984-1.21A6.672 6.672 0 01.688 5.672a4.424 4.424 0 01-.514-3.6l.186-.538h2.089C2.431.607 2.481 0 2.481 0h11.061s.051.607.033 1.534h2.192l.149.587a4.8 4.8 0 01-.669 3.469 6.778 6.778 0 01-3.228 2.53A6.148 6.148 0 0111 9.4a4.34 4.34 0 01-2.193 1.114l.009 4.224c2.21.02 3.933 1.4 3.933 3.022v.6zM6.275 7.24l1.752-.89 1.742.889.045-.011-.3-1.912 1.436-1.407-2.015-.284-.908-1.748-.9 1.748-2.077.313 1.488 1.377-.307 1.914zm-4.069-2.3a3.789 3.789 0 00.847.915 15.489 15.489 0 01-.514-2.776h-.81a3.047 3.047 0 00.477 1.857zM13 5.783a4.11 4.11 0 00.894-1 3.423 3.423 0 00.529-1.72h-.933a15.7 15.7 0 01-.49 2.72z" />
  </svg>,
  group: props => <svg width="1em" height="1em" viewBox="0 0 19.173 16" {...props}>
    <path d="M13.825 16a.837.837 0 01-.787-.888l.283-5.144a1.38 1.38 0 00.262-.1 1.291 1.291 0 00.686-.767 1.307 1.307 0 00-.061-1.029l-1.332-2.786a2.972 2.972 0 00-.141-.263 2.969 2.969 0 015.044.524l1.331 2.785a.756.756 0 01-.345.969.814.814 0 01-.3.06.7.7 0 01-.645-.4l-1.089-2.3.464 8.452a.823.823 0 01-.787.888h-.04a.84.84 0 01-.847-.787l-.283-5.144h-.242l-.283 5.144a.835.835 0 01-.848.787zm-11.036-.022A.837.837 0 012 15.09l.444-8.453-1.089 2.3a.708.708 0 01-.948.343.707.707 0 01-.343-.948l1.374-2.785a2.969 2.969 0 015.043-.524 1.762 1.762 0 00-.14.263L5.009 8.07a1.3 1.3 0 00-.061 1.03 1.353 1.353 0 00.685.767 1.021 1.021 0 00.263.1l.282 5.124a.825.825 0 01-.787.888h-.04a.843.843 0 01-.848-.787l-.282-5.145h-.282l-.263 5.145a.835.835 0 01-.848.787zm5.506 0a.835.835 0 01-.787-.887l.444-8.453-1.089 2.3a.713.713 0 11-1.291-.605L6.9 5.548a2.972 2.972 0 015.366 0L13.6 8.332a.726.726 0 01-.306.948.819.819 0 01-.3.061.7.7 0 01-.645-.4l-1.089-2.3.44 8.449a.822.822 0 01-.786.887h-.041a.841.841 0 01-.847-.786l-.283-5.145h-.279l-.283 5.145a.833.833 0 01-.846.787zm5.066-14.243A1.734 1.734 0 1115.1 3.471a1.735 1.735 0 01-1.739-1.736zm-5.508 0a1.735 1.735 0 111.735 1.736 1.735 1.735 0 01-1.735-1.736zm-5.507 0a1.735 1.735 0 111.735 1.736 1.735 1.735 0 01-1.735-1.736z" />
  </svg>,
  addGroup: props => <svg width="1em" height="1em" viewBox="0 0 22.168 18.01" {...props}>
    <path d="M5.51 0.5a1.915 1.915 0 101.91 1.91 1.918 1.918 0 00-1.91-1.91zm6.04-.05a1.934 1.934 0 00-1.88 1.96v.09a1.921 1.921 0 003.84-.09v-.08a1.934 1.934 0 00-1.96-1.88zm6.08 0a1.934 1.934 0 00-1.88 1.96v.09a1.921 1.921 0 003.84-.09v-.08a1.934 1.934 0 00-1.96-1.88zm-8.21 14.48l-.13 2.51a.946.946 0 00.87 1l.04-.01a.92.92 0 00.94-.88l.14-2.62zm6.6-5.1l-1.47-3.13a3.253 3.253 0 00-5.92 0l-.97 2.07v1.5h1.03l1.09-2.34-.12 2.34h1.99v1.49h.11l.32 5.79a.94.94 0 00.93.89h.08a.933.933 0 00.84-1l-.49-9.51 1.2 2.59a.776.776 0 00.71.45.724.724 0 00.33-.07.825.825 0 00.34-1.07zm-9.13-4.74a3.251 3.251 0 00-4.3 1.61l-1.52 3.14a.729.729 0 00-.07.43h1.62l.38-.83v-3.17h4.66v.86l.34-.72c.05-.1.1-.2.16-.3a3.325 3.325 0 00-1.27-1.02zm15.21 4.75l-1.47-3.14a3.289 3.289 0 00-2.96-1.9 3.24 3.24 0 00-2.6 1.31c.04.09.11.19.15.3l1.47 3.13a1.507 1.507 0 01.07 1.16 1.437 1.437 0 01-.76.86 1.022 1.022 0 01-.29.11l-.31 5.79a.926.926 0 00.87 1h.04a.927.927 0 00.94-.88l.31-5.79h.27l.31 5.79a.939.939 0 00.93.88h.08a.927.927 0 00.84-1l-.52-9.51 1.2 2.59a.768.768 0 00.72.45.96.96 0 00.33-.06.866.866 0 00.38-1.09z" />
    <path d="M10.65 11.27v2.66h-3.99v3.99h-2.66v-3.99h-4v-2.66h4v-4h2.66v4z" />
  </svg>,
  ball: props => <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
    <path d="M7.998 0a8 8 0 108 8 8 8 0 00-8-8zm1.521 14.269a6.078 6.078 0 01-3.041 0v-.024l-.7-2.129 1.193-1.637H9.03l1.193 1.637-.7 2.129a.205.205 0 000 .024zm1.123-6.479l1.942-.631 1.825 1.31.024.024a6.252 6.252 0 01-.935 2.877h-2.293l-1.193-1.637zm2.245-3.93l-.7 2.129-1.989.631-1.614-1.17V3.369l1.825-1.31.024-.024a6.45 6.45 0 012.456 1.778c.023.023.023.023 0 .047zm-9.1 2.129l-.7-2.129c0-.024 0-.024-.024-.047a6.46 6.46 0 012.456-1.778l.024.024 1.825 1.333v2.081l-1.614 1.17zm1.567 1.8l.631 1.942-1.187 1.638h-2.3a6.34 6.34 0 01-.936-2.878l.024-.024 1.825-1.333z" />
  </svg>,
  cross: props => <svg width="1em" height="1em" viewBox="0 0 9.43 11.336" {...props}>
    <path d="M4.715 7.155L0 11.336V8.352l3.021-2.684L0 2.947V0l4.715 4.181L9.43 0v2.984L6.409 5.668 9.43 8.389v2.947z" />
  </svg>,
  whistle: props => <svg width="1em" height="1em" viewBox="0 0 16.674 15.937" {...props}>
    <path d="M4.059 1.6a.425.425 0 10-.713.461L4.561 3.82a.425.425 0 00.713-.461zM8.331.469a.459.459 0 00-.5.335l-.379 2.09a.459.459 0 00.335.5.434.434 0 00.5-.335L8.664.965a.4.4 0 00-.333-.496zM6.194.301a.409.409 0 00-.5-.293.434.434 0 00-.335.5l.461 2.094a.459.459 0 00.5.335.434.434 0 00.335-.5zM16.667 6.374l-.67-3.183a.424.424 0 00-.5-.335L4.059 5.288a5.311 5.311 0 00-3.476 7.62 5.431 5.431 0 0010.136-3.727.859.859 0 01.67-1.047l4.943-1.3a.387.387 0 00.335-.462z" />
  </svg>,
  swap: ({ clubColor, ...props }) => <svg width="1em" height="1em" viewBox="0 0 22.659 18.12" {...props}>
    <defs><style>{`.prefix__cls-1{fill:${clubColor}}`}</style></defs>
    <path d="M6 0.5a1.915 1.915 0 101.91 1.91 1.918 1.918 0 00-1.91-1.91zm6.04-.05a1.934 1.934 0 00-1.88 1.96v.09a1.921 1.921 0 003.84-.09v-.08a1.934 1.934 0 00-1.96-1.88zm6.08 0a1.934 1.934 0 00-1.88 1.96v.09a1.921 1.921 0 003.84-.09v-.08a1.934 1.934 0 00-1.96-1.88zm-8.01 10.53l-.06 1.22a.481.481 0 00.07-.24v-.88a.34.34 0 00-.01-.1zm1.35 6.18l-.07.05-1.29.99a.921.921 0 00.55.24l.04-.01a.92.92 0 00.94-.88l.03-.6a1.339 1.339 0 01-.2.21zm5.05-7.33l-1.47-3.13a3.253 3.253 0 00-5.92 0l-1.35 2.88h1.73l.77-1.65-.09 1.76a1.544 1.544 0 01.51.33 1.487 1.487 0 01.43 1.07v.88a1.5 1.5 0 01-1.13 1.43l-.02.33 1.43 1.09a1.444 1.444 0 01.35.39l.19-3.45h.31l.32 5.79a.94.94 0 00.93.89h.08a.933.933 0 00.84-1l-.49-9.51 1.2 2.59a.776.776 0 00.71.45.724.724 0 00.33-.07.825.825 0 00.34-1.07zm-9.13-4.74a3.251 3.251 0 00-4.3 1.61l-.75 1.54a1.478 1.478 0 011.53-.12.761.761 0 01.19.12l.14-.31-.02.4a1.46 1.46 0 01.52 1.13v.12h2.32a.06.06 0 01.01-.04l1.47-3.13c.05-.1.1-.2.16-.3a3.325 3.325 0 00-1.27-1.02zm15.21 4.75l-1.47-3.14a3.289 3.289 0 00-2.96-1.9 3.24 3.24 0 00-2.6 1.31c.04.09.11.19.15.3l1.47 3.13a1.507 1.507 0 01.07 1.16 1.437 1.437 0 01-.76.86 1.022 1.022 0 01-.29.11l-.31 5.79a.926.926 0 00.87 1h.04a.927.927 0 00.94-.88l.31-5.79h.27l.31 5.79a.939.939 0 00.93.88h.08a.927.927 0 00.84-1l-.52-9.51 1.2 2.59a.768.768 0 00.72.45.96.96 0 00.33-.06.866.866 0 00.38-1.09z"/>
    <path className="prefix__cls-1" d="M11 16a.484.484 0 01-.18.39l-.06.06-.96.73-1.7 1.29a.492.492 0 01-.3.1.593.593 0 01-.23-.05.443.443 0 01-.11-.08.523.523 0 01-.16-.37v-1.15h-5.92a.5.5 0 01-.5-.5v-.87a.5.5 0 01.5-.5h5.92v-1.09a.5.5 0 01.27-.45.53.53 0 01.53.05l.01.01 1.81 1.37.88.67a.518.518 0 01.2.39z" />
    <path className="prefix__cls-1" d="M3.12 14.05a.2.2 0 00.08.01.41.41 0 00.11-.01.487.487 0 00.11-.04.5.5 0 00.28-.45v-1.06a.09.09 0 00-.01-.05h5.93a.5.5 0 00.43-.25.481.481 0 00.07-.24v-.88a.34.34 0 00-.01-.1v-.02a.5.5 0 00-.49-.38h-5.93v-1.12a.5.5 0 00-.08-.27.414.414 0 00-.19-.18.53.53 0 00-.53.05l-1.4 1.07-1.23.93-.09.08a.475.475 0 00-.17.39.508.508 0 00.19.39l2.7 2.04a.5.5 0 00.23.09z" />
  </svg>,
  mobileIcon: props => <svg width="1em" height="1em" viewBox="0 0 25.46 28.666" {...props}>
    <defs><style>{'.prefix__d{fill:#000}'}</style></defs>
    <path d="M23.22 11.49l-1.31-1.31-4.54 4.539a20.4 20.4 0 00.56-6.309s-4.31-.451-6.12-1.56C10.01 7.959 5.7 8.41 5.7 8.41c-.45 8.51 3.28 11.78 5.12 12.859l-1.43 1.43-.28.281-.37 1.6-.16.69H6.787c-3.47-3.4-7.36-10-6.72-22.269 0 0 8.28-.88 11.74-3 3.46 2.12 11.74 3 11.74 3a43.071 43.071 0 01-.327 8.489z" style={{ isolation: 'isolate' }} fill="#c31c1c" />
    <path d="M21.6 10.49l-4.23 4.229a20.4 20.4 0 00.56-6.309s-4.31-.451-6.12-1.56C10.01 7.959 5.7 8.41 5.7 8.41c-.45 8.51 3.28 11.78 5.12 12.859l-1.43 1.43-.28.281-.37 1.6c-3.13-2.469-7.45-8.05-6.83-19.81 0 0 6.98-.74 9.9-2.529 2.92 1.789 9.9 2.529 9.9 2.529a37.953 37.953 0 01-.11 5.72z" style={{ isolation: 'isolate' }} fill="#fdea18" />
    <path d="M19.6 12.49l-2.23 2.229a20.4 20.4 0 00.56-6.309s-4.31-.451-6.12-1.56C10.01 7.959 5.7 8.41 5.7 8.41c-.45 8.51 3.28 11.78 5.12 12.859l-1.43 1.43C6.85 20.73 3.27 16.209 3.78 6.57c0 0 5.66-.611 8.03-2.061 2.37 1.45 8.04 2.061 8.04 2.061a28.4 28.4 0 01-.25 5.92z" style={{ isolation: 'isolate' }} fill="#1d1e1c" />
    <path className="prefix__d" d="M20.12 14.996l1.789-1.789 3.551 3.551-1.789 1.79zM11.556 23.56l7.97-7.97 3.552 3.55-7.97 7.971zM11.014 24.208l-1.012 4.458 4.458-1.012zM.038 27.488h8.953v1.175H.038z" />
  </svg>,
  analyticsIcon: props => <svg width="1em" height="1em" viewBox="0 0 37.202 44.258" {...props}>
    <defs><style>{'.prefix__a{fill:#c31c1c;isolation:isolate}.prefix__f{fill-rule:evenodd}'}</style></defs>
    <path className="prefix__a" d="M25.219 34.081v.093h-.108zM35.163 21.448a15.819 15.819 0 00-2.3-1.745 14.549 14.549 0 00-5.235-1.96 34.435 34.435 0 00.046-4.756s-6.656-.7-9.435-2.409c-2.8 1.713-9.451 2.409-9.451 2.409-.34 6.47.88 10.978 2.548 14.082a14.568 14.568 0 00-.88 3.66c-.046.527-.077 1.05-.077 1.577a15.881 15.881 0 00.293 2.98v.016a14.881 14.881 0 003.181 6.53c-5.745-4-14.964-14.083-13.744-37.2 0 0 12.771-1.359 18.129-4.633 5.343 3.274 18.114 4.633 18.114 4.633a61.934 61.934 0 01-1.189 16.816z" />
    <path d="M25.219 34.081v.093h-.108z" style={{ isolation: 'isolate' }} fill="#fdea18" />
    <path d="M32.863 19.703a14.548 14.548 0 00-5.235-1.96 34.437 34.437 0 00.046-4.756s-6.656-.7-9.435-2.409c-2.8 1.713-9.451 2.409-9.451 2.409-.34 6.47.88 10.978 2.548 14.082a14.568 14.568 0 00-.88 3.66c-.046.527-.077 1.05-.077 1.577a15.887 15.887 0 00.293 2.98C6.441 30.591 2.179 22.143 2.951 7.365c0 0 10.779-1.143 15.288-3.905 4.509 2.763 15.272 3.905 15.272 3.905a53.858 53.858 0 01-.648 12.338z" style={{ isolation: 'isolate' }} fill="#ffea00" />
    <path d="M30.376 18.483a16.363 16.363 0 00-2.749-.74 34.435 34.435 0 00.046-4.756s-6.656-.7-9.435-2.409c-2.8 1.713-9.451 2.409-9.451 2.409-.34 6.47.88 10.978 2.548 14.082a14.567 14.567 0 00-.88 3.66c-2.811-4.061-5.158-10.516-4.633-20.583 0 0 8.74-.943 12.415-3.183 3.66 2.239 12.4 3.183 12.4 3.183a46.383 46.383 0 01-.261 8.337z" style={{ isolation: 'isolate' }} fill="#1d1e1c" />
    <path d="M25.219 34.081v.093h-.108z" fill="#fff" />
    <path className="prefix__f" d="M37.202 31.608H25.821v-11.24a9.152 9.152 0 011.467.2 10.931 10.931 0 012.61.757v.016a12.064 12.064 0 012.347 1.3 10.381 10.381 0 011.436 1.2 9.489 9.489 0 01.664.709 11.666 11.666 0 012.857 7.058zM37.063 34.174a12.018 12.018 0 01-16.739 9.049.753.753 0 01-.17-.063 10.445 10.445 0 01-3.3-2.193l.664-.554 7.582-6.239zM23.258 20.504v11.892l-5.59 4.585-2.486 2.054a11.561 11.561 0 01-1.328-2.562 10.4 10.4 0 01-.664-2.533 10.183 10.183 0 01-.124-1.685 11.576 11.576 0 01.232-2.317 11.266 11.266 0 013.166-5.868 11.7 11.7 0 016.794-3.566z" />
  </svg>,
  mobile: props => <svg width="1em" height="1em" viewBox="0 0 30.897 52.244" {...props}>
    <path d="M26.403 0H4.494A4.5 4.5 0 000 4.494V47.75a4.5 4.5 0 004.494 4.494h21.909a4.5 4.5 0 004.494-4.494V4.494A4.5 4.5 0 0026.403 0zM15.454 48.254a2.247 2.247 0 111.588-.658 2.244 2.244 0 01-1.588.658zm12.078-7.47H3.37V6.01h24.156z" />
  </svg>,
  menu: props => <svg width="1em" height="1em" viewBox="0 0 5.222 19.544" {...props}>
    <path d="M5.222 2.612A2.611 2.611 0 112.611 0a2.624 2.624 0 012.611 2.612M5.222 9.772a2.611 2.611 0 11-2.611-2.611 2.624 2.624 0 012.611 2.611M5.222 16.933a2.611 2.611 0 11-2.611-2.611 2.624 2.624 0 012.611 2.611" />
  </svg>,
  addPerson: ({ clubColor, ...props }) => <svg width="1em" height="1em" viewBox="0 0 22.229 18.323" {...props}>
    <path d="M22.229 16.609a1.717 1.717 0 01-1.722 1.714H7.695v-3.006h4.009v-4.7H9.471a5.076 5.076 0 01-1.487-3.583V5.002a5.079 5.079 0 0110.156 0v2.032a5.086 5.086 0 01-2.368 4.285 10.819 10.819 0 014.912 2.822 10.929 10.929 0 011.26 1.517 1.731 1.731 0 01.285.951z" />
    <path d="M10.689 11.632v2.673H6.68v4.007H4.009v-4.01H0v-2.67h4.009v-4.01H6.68v4.01z" fill={clubColor} />
  </svg>,
  clock: props => <svg width="1em" height="1em" viewBox="0 0 21.722 21.722" {...props}>
    <path d="M13.361,2.5A10.861,10.861,0,1,0,24.222,13.361,10.871,10.871,0,0,0,13.361,2.5Zm4.276,13.857a1.044,1.044,0,0,1-.869.457,1.213,1.213,0,0,1-.617-.183l-3.406-2.4a1.106,1.106,0,0,1-.457-.869V7.621a1.074,1.074,0,0,1,2.149,0v5.19l2.949,2.081a1.034,1.034,0,0,1,.252,1.464Z" transform="translate(-2.5 -2.5)" />
  </svg>
}

const Icon = ({ name, ...props }) => name ? icons[name](props) : null

Icon.propTypes = {
  name: PropTypes.oneOf([
    '',
    'eye',
    'today',
    'key',
    'repeat',
    'search',
    'asterisk',
    'talk',
    'phone',
    'soccer',
    'shield',
    'heart',
    'delete',
    'shoes',
    'email',
    'calendar',
    'clothing',
    'gender',
    'present',
    'text',
    'person',
    'danger',
    'gear',
    'check',
    'back',
    'plus',
    'cup',
    'group',
    'addGroup',
    'ball',
    'cross',
    'whistle',
    'swap',
    'mobileIcon',
    'analyticsIcon',
    'mobile',
    'menu',
    'addPerson',
    'clock'
  ])
}

export default Icon
