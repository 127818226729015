function setToken (token) {
  window.localStorage.setItem('token', token)
}

function getToken () {
  return window.localStorage.getItem('token')
}

function removeToken () {
  return window.localStorage.removeItem('token')
}

export { setToken, getToken, removeToken }
