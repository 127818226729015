import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import './Modal.scss'
import removeFalsyFromArray from '../../removeFalsyFromArray.js'
import PropTypes from 'prop-types'

const Modal = ({ onClose, active, children }) => {
  const classes = removeFalsyFromArray([
    'Modal-root',
    active && 'Modal-active'
  ])

  useEffect(() => {
    if (!active) return
    function handleKeyEvent (e) {
      if (e.key === 'Escape') {
        onClose()
      }
    }
    window.addEventListener('keydown', handleKeyEvent)
    return () => window.removeEventListener('keydown', handleKeyEvent)
  }, [active, onClose])

  function handleClose (e) {
    if (e.target.classList.contains('Modal-root')) {
      onClose(e)
    }
  }

  return createPortal(
    <div className={classes.join(' ')} onClick={handleClose}>
      <div className="Modal-container">
        {children}
      </div>
    </div>,
    document.body
  )
}

Modal.propTypes = {
  onClose: PropTypes.func,
  active: PropTypes.bool,
  children: PropTypes.node
}

export default Modal
