function setAppState (newState) {
  return {
    type: 'SET_APP_STATE',
    newState
  }
}

export {
  setAppState
}
