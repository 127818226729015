import host from '../host.js'
import patch from './patch.js'

function updateUser (id, user) {
  const url = `${host}/user/${id}`
  const body = new FormData()
  const { profilePicture, clearProfilePicture = false, ...json } = user
  body.append('json', JSON.stringify(json))
  if (profilePicture) {
    body.append('profilePicture', profilePicture)
  } else if (clearProfilePicture) {
    body.append('clearProfilePicture', 'true')
  }
  return patch(url, { body })
}

export default updateUser
