import React from 'react'
import i18n from '../../translations/i18n.js'
import Text from '../../components/Text/Text.js'

const AndroidNoSupport = () => {
  return (
    <div className="InstallAndroidNoSupport">
      <div className="InstallAndroidNoSupport-message">
        <Text size={14}>{i18n.t('installation:different_browser_message')}</Text>
        <div>
          <img alt="chrome" src="/image/chrome.png" />
          <img alt="brave" src="/image/brave.png" />
          <img alt="edge" src="/image/edge.png" />
        </div>
      </div>
    </div>
  )
}

export default AndroidNoSupport
