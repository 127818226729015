import React, { useState } from 'react'
import './Input.scss'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon.js'
import removeFalsyFromArray from '../../removeFalsyFromArray.js'
import i18n from '../../translations/i18n.js'
import Text from '../Text/Text.js'

const InputContainer = ({ children }) => <div className="Input-container">{children}</div>

InputContainer.propTypes = {
  children: PropTypes.node
}

const Input = React.forwardRef(({ onChange, label, iconName, required, value, error, type = 'text', accept, disabled, optional, onBlur = () => {} }, ref) => {
  const [showPassword, setShowPassword] = useState(false)
  const classes = removeFalsyFromArray([
    'Input-root',
    error && 'Input-error',
    disabled && 'Input-disabled',
    type === 'textarea' && 'Input-textarea'
  ])
  return (
    <label className={classes.join(' ')}>
      <div className="Input-icon">
        {iconName && <Icon width={16} height={16} name={iconName} fill="var(--grey)" />}
        {required && !disabled && <div className="Input-icon-required"><Icon name="asterisk" width={8} height={8} fill="var(--grey)" /></div>}
      </div>
      { type === 'textarea'
        ? <textarea onChange={onChange} value={value} placeholder={label} disabled={disabled}/>
        : <input ref={ref} accept={accept} onChange={onChange} value={value} placeholder={label} type={showPassword ? 'text' : type} disabled={disabled} onBlur={onBlur} />
      }
      { optional && !disabled && <div className="Input-optional"><Text color="grey" transform="uppercase" size={10}>{i18n.t('common:optional')}</Text></div> }
      <div className="Input-warning-icon"><Icon name="danger" fill="var(--red)" width={24} height={24} /></div>
      { type === 'password' && <div className="Input-password-icon" onClick={() => setShowPassword(!showPassword)}><Icon name="eye" width={16} height={16} fill={showPassword ? 'var(--purple)' : 'var(--black)'} /></div> }
    </label>
  )
})

Input.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  iconName: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'password', 'number', 'email', 'date', 'textarea', 'file']),
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  optional: PropTypes.bool,
  onBlur: PropTypes.func
}

export default Input
export { InputContainer }
