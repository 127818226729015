import React, { useState, useCallback } from 'react'
import './ListItem.scss'
import Text from '../Text/Text.js'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon.js'
import removeFalsyFromArray from '../../removeFalsyFromArray.js'

const ListItem = ({ beforeIcon, beforeName, afterName, name, comment, iconName, iconColor = 'grey', onClick }) => {
  const [depth, setDepth] = useState(0)
  const liRef = useCallback(node => {
    if (!node) return
    const ul = node.closest('ul')
    const depth = ul ? ul.dataset.depth : 0
    setDepth(depth)
  }, [])

  const classes = removeFalsyFromArray([
    'ListItem-root',
    onClick && 'ListItem-hasAction'
  ])

  return (
    <li className={classes.join(' ')} ref={liRef} style={{ paddingLeft: (40 * depth) + 24 }} onClick={onClick}>
      { beforeIcon }
      { iconName && <div className="ListItem-icon"><Icon fill={`var(--${iconColor})`} width={16} height={16} name={iconName} /></div> }
      { beforeName }
      <div className="ListItem-text">
        <Text size={14}>{name}</Text>
        { comment && <Text size={10} font="roboto" color="grey" >{comment}</Text> }
      </div>
      { afterName }
    </li>
  )
}

ListItem.propTypes = {
  beforeIcon: PropTypes.node,
  beforeName: PropTypes.node,
  afterName: PropTypes.node,
  name: PropTypes.string,
  comment: PropTypes.string,
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
  onClick: PropTypes.func
}

export default ListItem
