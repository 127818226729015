import { useRef, useEffect } from 'react'

function useScrollToInputOnResize () {
  const scrollThrottle = useRef({ counter: 0 })
  useEffect(() => {
    function scroll () {
      const activeElement = document.activeElement
      if (!activeElement) return
      activeElement.scrollIntoView()
    }
    function scrollIntoView () {
      let newCount = scrollThrottle.current.counter + 1
      if (newCount < 5) {
        clearTimeout(scrollThrottle.current.timeout)
      } else {
        newCount = 0
      }
      scrollThrottle.current = {
        counter: newCount,
        timeout: setTimeout(() => scroll(), 100)
      }
    }
    window.addEventListener('resize', scrollIntoView)
    return () => window.removeEventListener('resize', scrollIntoView)
  }, [])
  return []
}

export default useScrollToInputOnResize
