import history from './history.js'
import { removeToken } from './token.js'
import store from './redux/store.js'
import { removeTeamId } from './currentTeamId.js'

function handleLogout () {
  removeToken()
  removeTeamId()
  store.dispatch({ type: 'USER_LOGOUT' })
  history.push('/login')
}

export default handleLogout
