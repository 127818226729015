import React from 'react'
import i18n from '../../translations/i18n.js'
import Text from '../../components/Text/Text.js'

const Arrow = () => {
  return (
    <svg width={100.047} height={178.399} viewBox="0 0 100.047 178.399">
      <path d="M97.064 6.386h2.983L94.538.172l-.162-.169-5.662 6.385h2.944l2.721-3.021z" fill="#b2b1b1" />
      <path d="M94.38 3.193S94.67 176.572.009 177.4" fill="none" stroke="#b2b1b1" strokeWidth={2} />
    </svg>
  )
}

const InstallArrow = () => {
  return (
    <svg width={28} height={28} viewBox="0 0 28 28">
      <g fill="#fff" stroke="#000" strokeWidth={3}><rect width={28} height={28} rx={11} stroke="none" /><rect x={1.5} y={1.5} width={25} height={25} rx={9.5} fill="none" /></g>
      <path d="M20.033 15.24l-4.976 4.173V5.732h-2.309v13.681L7.777 15.24l-1.62 1.359 7.748 6.5 7.748-6.5z" />
    </svg>
  )
}

const AndroidSamsung = () => {
  return (
    <div className="InstallAndroidSamsung">
      <div className="InstallAndroidSamsung-message">
        <Text size={16} weight="bold" color="red">{i18n.t('installation:install_the_app')}</Text>
        <Text size={14} color="black">{i18n.t('installation:tap_the_icon')}</Text>
        <div className="Install-preview-button"><InstallArrow /></div>
        <div className="InstallAndroidSamsung-arrow"><Arrow /></div>
      </div>
    </div>
  )
}

export default AndroidSamsung
