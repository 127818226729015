import ky from 'ky'
import { getToken } from '../token.js'

function post (url, kyOptions, { useAuth = true, ...options } = {}) {
  const customOptions = {}
  if (useAuth) {
    customOptions.headers = {
      authorization: `Bearer ${getToken()}`
    }
  }
  return ky.post(url, {
    ...customOptions,
    ...kyOptions
  })
}

export default post
