import React from 'react'
import i18n from '../../translations/i18n.js'
import Text from '../../components/Text/Text.js'

const Arrow = () => {
  return (
    <svg width={80.821} height={178.399} viewBox="0 0 80.821 178.399">
      <path d="M77.837 6.385h2.984L75.312.172 75.15 0l-5.662 6.385h2.944l2.721-3.021z" fill="#b2b1b1" />
      <path d="M75.153 3.193s4.959 87.9-12.5 138.776C51.803 173.59 29.102 177.08.008 177.399" fill="none" stroke="#b2b1b1" strokeWidth={2} />
    </svg>
  )
}

const InstallArrow = () => {
  return (
    <svg width={23.801} height={27.31} viewBox="0 0 23.801 27.31">
      <defs><style>{'.prefix__a{fill:#161615}'}</style></defs>
      <path className="prefix__a" d="M23.411 12.76L11.9 0 .39 12.76a1.5 1.5 0 102.221 2.01l.089-.1v9.78a2.627 2.627 0 002.31 2.86h14.37a2.626 2.626 0 002.31-2.86v-9.33a1.3 1.3 0 00.611.14 1.495 1.495 0 001.11-2.5zM18.69 24.31H5.7V11.97l6.36-7.22 6.63 7.54z" />
      <path className="prefix__a" d="M17.63 17.3a1.007 1.007 0 01-1.01 1.01H13v3.61a1.004 1.004 0 01-2.009 0V18.3H7.38a1.005 1.005 0 110-2.01H11v-3.61a1 1 0 011-1h.01a1 1 0 011 1v3.62h3.61a1 1 0 011.01 1z" />
    </svg>
  )
}

const AndroidFirefox = () => {
  return (
    <div className="InstallAndroidFirefox">
      <div className="InstallAndroidFirefox-message">
        <Text size={16} weight="bold" color="red">{i18n.t('installation:install_the_app')}</Text>
        <Text size={14} color="black">{i18n.t('installation:tap_the_icon')}</Text>
        <div className="Install-preview-button"><InstallArrow /></div>
        <div className="InstallAndroidFirefox-arrow"><Arrow /></div>
      </div>
    </div>
  )
}

export default AndroidFirefox
