import { format } from 'date-fns'
import { nl, fr } from 'date-fns/locale'

const languages = {
  nl,
  fr
}
let selectedLanguage = languages.nl

// https://date-fns.org/v2.8.1/docs/I18n
// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
const formatFn = function (date, formatStr = 'PP') {
  return format(date, formatStr, {
    locale: selectedLanguage
  })
}

function changeLanguage (lang) {
  selectedLanguage = languages[lang]
}

export default formatFn
export {
  changeLanguage
}
