import React from 'react'
import { createPortal } from 'react-dom'
import './EmbeddedCreateMember.scss'
import CreateMember from '../CreateMember/CreateMember.js'

const EmbeddedCreateMember = ({ onClose, onMemberCreated }) => {
  return createPortal(
    <div className="EmbeddedCreateMember-root">
      <CreateMember embedded={true} embeddedOnClose={onClose} embeddedOnNewMember={onMemberCreated} />
    </div>,
    document.body
  )
}

export default EmbeddedCreateMember
