import React, { useState, useMemo } from 'react'
import { createPortal } from 'react-dom'
import './PhoneInput.scss'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon.js'
import removeFalsyFromArray from '../../removeFalsyFromArray.js'
import Input from '../Input/Input.js'
import { getCountries, getCountryCallingCode } from 'libphonenumber-js'
import Header from '../Header/Header.js'
import Text from '../Text/Text.js'

const allCodes = getCountries().map(country => ({ country, code: getCountryCallingCode(country) }))

const Search = ({ onChange, value }) => (
  <div className="PhoneInput-country-picker-filter">
    <input onChange={onChange} value={value} />
  </div>
)

const CodePicker = ({ onClose, visible, onChange }) => {
  const [filter, setFilter] = useState('')

  const classes = removeFalsyFromArray([
    'PhoneInput-country-picker',
    visible && 'PhoneInput-country-picker-visible'
  ])

  function handleRowClick (code) {
    return e => {
      onChange(code)
      onClose()
    }
  }

  function handleFilterChange (e) {
    setFilter(e.target.value.toLowerCase())
  }

  const filteredCodes = useMemo(() => {
    return allCodes.filter(code => ~code.country.toLowerCase().indexOf(filter))
  }, [filter])

  return createPortal(
    <div className={classes.join(' ')}>
      <Header
        leftAction="back"
        onLeftAction={onClose}
        customTitle={<Search value={filter} onChange={handleFilterChange} />}
      />
      <div className="PhoneInput-country-picker-list">
        { filteredCodes.map(code =>
          <div className="PhoneInput-country-picker-row" key={code.country + code.code} onClick={handleRowClick(code.code)}>
            <Text size={14}>{code.country}</Text>
            <Text size={14}>{code.code}</Text>
          </div>
        ) }
      </div>
    </div>,
    document.body
  )
}

const PhoneInput = ({ onChange, label, iconName, value, disabled, onFocus = () => {}, onBlur = () => {} }) => {
  const [showCountryPicker, setShowCountryPicker] = useState(false)

  const classes = removeFalsyFromArray([
    'PhoneInput-root',
    disabled && 'PhoneInput-disabled'
  ])

  function splitNumber (number = '') {
    if (number[0] !== '+') return ['32', number]
    const one = allCodes.find(code => code.code === number.slice(1, 2))
    const two = allCodes.find(code => code.code === number.slice(1, 3))
    const three = allCodes.find(code => code.code === number.slice(1, 4))
    const code = (one || two || three || { code: '32' }).code
    return [
      code,
      number.replace(`+${code}`, '')
    ]
  }

  const [countryCallingCode, number] = splitNumber(value)

  function handleCodeChange (code) {
    if (disabled) return
    onChange('+' + code + number)
  }

  function handleInputChange (e) {
    if (disabled) return
    onChange('+' + countryCallingCode + e.target.value)
  }

  function handleCountryCodeClick () {
    if (disabled) return
    setShowCountryPicker(true)
    onFocus()
  }

  function handleCountryCodeClose () {
    setShowCountryPicker(false)
    onBlur()
  }

  return (
    <div className={classes.join(' ')}>
      <div className="PhoneInput-icon" onClick={handleCountryCodeClick}><Icon width={16} height={16} fill="var(--grey) " name={iconName} /></div>
      <div className="PhoneInput-number" onClick={handleCountryCodeClick}>
        <Text size={14}>+{countryCallingCode}</Text>
      </div>
      <CodePicker onClose={handleCountryCodeClose} visible={showCountryPicker} onChange={handleCodeChange} />
      <Input disabled={disabled} label={label} value={number} type="number" onChange={handleInputChange} />
    </div>
  )
}

PhoneInput.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  iconName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
}

export default PhoneInput
