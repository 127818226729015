function setUser (user) {
  return {
    type: 'SET_USER',
    user
  }
}

function setUserLoading () {
  return {
    type: 'SET_USER_LOADING'
  }
}

function setUserError (error) {
  return {
    type: 'SET_USER_ERROR',
    error
  }
}

export {
  setUser,
  setUserLoading,
  setUserError
}
