import UniversalRouter from 'universal-router'
import routes from './routes.js'
import history from './history.js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App/App.js'
import { getToken, removeToken } from './token.js'
import store from './redux/store.js'
import validate from './connector/validateToken.js'
import initializeUser from './initializeUser.js'
import NotFound from './pages/NotFound/NotFound.js'

const notify = console.log

const options = {
  errorHandler (error, context) {
    console.error(error)
    console.info(context)
    if (error.status === 404) {
      return NotFound()
    } else {
      notify('error', 'Error', error.toString())
      return null
    }
  }
}

const router = new UniversalRouter(routes, options)

const root = document.getElementById('root')
function render (location) {
  // verify auth token
  // if ok
  //   if pathname === login
  //   redirect to /
  // if nok
  //   if (pathname !== login)
  //   redirect to /login

  const destination = location.pathname

  const token = getToken()
  const noAuthDestinations = new Set(['/login', '/requestPasswordReset'])
  if (!token && !noAuthDestinations.has(destination)) {
    // notify('info', 'redirect', `redirected from ${destination}`)
    return history.push('/login')
  } else if (token && noAuthDestinations.has(destination)) {
    // notify('info', 'redirect', `redirected from ${destination}`)
    return history.push('/activities')
  }

  const currentUser = store.getState().user

  router.resolve({
    pathname: destination,
    context: {
      user: currentUser
    }
  }).then(component => {
    if (component.redirect) {
      history.push(component.redirect)
    } else {
      ReactDOM.render(
        <App>{component}</App>,
        root
      )
    }
  })
}

// Listen for changes to the current location.
// const unlisten =
history.listen((location, action) => {
  render(location)
})

// To stop listening, call the function returned from listen().
// unlisten()

// Render the app for the first time
async function initialize () {
  const token = getToken()
  if (!token) {
    return render(history.location)
  }

  let response
  try {
    // validate token
    response = await validate(token)
  } catch (error) {
    if (error.response && error.response.status === 401) {
      removeToken()
      history.push('/login')
      response = response || {}
    } else {
      return alert(error.toString())
    }
  }

  if (response.ok) {
    try {
      await initializeUser()
    } catch (error) {
      console.warn('couldn\'t get user\n', error)
      window.alert('invalid token')
      removeToken()
      history.push('/login')
    }
  // } else if (response.status === 401) {
  } else if (!response.ok) {
    removeToken()
    history.push('/login')
  } else {
    console.warn('unexpected response status', response)
    console.warn('token', token)
    removeToken()
    history.push('/login')
  }

  render(history.location)
}

initialize()
