import React, { useState, useEffect, useMemo } from 'react'
import './AddTeams.scss'
import { connect } from 'react-redux'
import i18n from '../../translations/i18n.js'
import Text from '../../components/Text/Text.js'
import Icon from '../../components/Icon/Icon.js'
import Modal from '../../components/Modal/Modal.js'
import Button from '../../components/Button/Button.js'
import Checkbox from '../../components/Checkbox/Checkbox.js'
import removeFalsyFromArray from '../../removeFalsyFromArray.js'

const AddTeams = ({ disabled, teams, myTeams, currentTeam, selectedTeams, setTeams, activity, onFocus = () => {}, onBlur = () => {} }) => {
  const [showModal, setShowModal] = useState(false)

  const disabledTeams = useMemo(() => {
    const set = new Set()
    set.add(currentTeam._id)
    if (!activity) return set
    const teamsWithAttendees = [...set, ...activity.attendingTeams.filter(team => (team.attendees || []).length || (team.coaches || []).length).map(team => (team.team || {})._id)]
    const notMyTeams = [...selectedTeams].filter(id => !myTeams.find(team => team._id === id))
    return new Set([...teamsWithAttendees, ...notMyTeams])
  }, [activity, currentTeam, selectedTeams, myTeams])

  const newSelected = useMemo(() => {
    const newSelected = []
    if (selectedTeams && selectedTeams.size && activity && teams.length) {
      for (const teamId of [...selectedTeams]) {
        if (!activity.attendingTeams.find(({ team = {} }) => team._id === teamId)) {
          newSelected.push(teams.find(team => team._id === teamId))
        }
      }
    }
    return newSelected
  }, [activity, selectedTeams, teams])

  function handleModalClose () {
    setShowModal(false)
    onBlur()
  }

  function handleOpenClick () {
    if (disabled) return
    setShowModal(true)
    onFocus()
  }

  function handleSubmit (teams) {
    setShowModal(false)
    onBlur()
    setTeams(teams)
  }

  const classes = removeFalsyFromArray(['AddTeams-root', disabled && 'AddTeams-disabled'])

  return (
    <div>
      <Modal onClose={handleModalClose} active={showModal}>
        <ModalBody teams={teams} onSubmit={handleSubmit} selectedTeams={selectedTeams} disabledTeams={disabledTeams} />
      </Modal>
      <div className={classes.join(' ')} onClick={handleOpenClick}>
        <div className="AddTeams-icon"><Icon name="addGroup" fill="var(--grey)" width={16} height={16} /></div>
        { newSelected.length
          ? <Text size={14} color="black">{newSelected.map(team => team.name).join(', ')}</Text>
          : <Text size={14} color="grey">{i18n.t('actions:add_another_team')}</Text>
        }
        { !disabled && <div className="AddTeams-optional"><Text color="grey" transform="uppercase" size={10}>{i18n.t('common:optional')}</Text></div> }
      </div>
    </div>
  )
}

const ModalBody = ({ teams, onSubmit, selectedTeams, disabledTeams }) => {
  const [checked, setChecked] = useState(new Set())
  useEffect(() => {
    setChecked(selectedTeams)
  }, [selectedTeams])

  function handleValueChange (id) {
    return e => {
      const newChecked = new Set(checked)
      if (newChecked.has(id)) {
        newChecked.delete(id)
      } else {
        newChecked.add(id)
      }
      setChecked(newChecked)
    }
  }

  function handleSubmit (e) {
    onSubmit(checked)
  }

  return (
    <div className="AddTeams-modal">
      { teams.map(team =>
        <label key={team._id} className="AddTeams-modal-row">
          <div className="AddTeams-modal-checkbox"><Checkbox disabled={disabledTeams.has(team._id)} onChange={handleValueChange(team._id)} checked={checked.has(team._id)} /></div>
          <Text size={14}>{team.name}</Text>
        </label>
      )}
      <Button onClick={handleSubmit}>{i18n.t('actions:add')}</Button>
    </div>
  )
}

function mapStateToProps (state) {
  return {
    myTeams: state.user.user.teams,
    currentTeam: state.appState.team
  }
}

export default connect(mapStateToProps)(AddTeams)
