import React from 'react'
import './SelectTeamModal.scss'
import Modal from '../../components/Modal/Modal.js'
import { connect } from 'react-redux'
import { setAppState } from '../../redux/appState/actions.js'
import List, { ListItem } from '../../components/List/List.js'
import { CreateSnackbarItem } from '../../components/Snackbar/Snackbar.js'
import i18n from '../../translations/i18n.js'
import { setTeamId } from '../../currentTeamId.js'

const SelectTeamModal = ({ active, teams, currentTeam, onClose, setTeam }) => {
  function handleTeamChange (team) {
    return e => {
      onClose()
      setTeam(team)
      setTeamId(team._id)
      CreateSnackbarItem({
        message: i18n.t('messages:team_set_to', { teamName: team.name || '' }),
        level: 'success'
      })
    }
  }

  const teamId = currentTeam._id
  const filteredTeams = teams.filter(team => team._id !== teamId)

  return (
    <div>
      <Modal onClose={onClose} active={active}>
        <div className="Select-list">
          <List>
            { filteredTeams.map(team =>
              <ListItem key={team._id} name={team.name} onClick={handleTeamChange(team)} />
            ) }
          </List>
        </div>
      </Modal>
    </div>
  )
}

function mapStateToProps (state) {
  const user = state.user.user || {}
  return {
    teams: user.teams || [],
    currentTeam: state.appState.team
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setTeam: team => dispatch(setAppState({ team }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectTeamModal)
