import Icon from '../../components/Icon/Icon'
import Text from '../../components/Text/Text'
import i18n from '../../translations/i18n'
import List, { ListItem } from '../../components/List/List'
import removeFalsyFromArray from '../../removeFalsyFromArray'
import getRoleIcon from '../../getRoleIcon'
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture'
import React from 'react'

const AttendeesDisplay = ({ members, attendees, singular, plural, showAddAction = false }) => {
  return <>
    {
      showAddAction
        ? <div className="AddAttendees-title">
          <div className="AddAttendees-icon">
            <Icon name="person" fill="var(--grey)"/>
          </div>
          {
            !attendees.size
              ? <Text size={14} color="grey">{i18n.t('actions:add_attendees')}</Text>
              : <div>{attendees.size} {attendees.size === 1 ? singular : plural}</div>
          }
        </div>
        : !!attendees.size && <div className="AddAttendees-title">
        <div className="AddAttendees-icon">
          <Icon name="person" fill="var(--grey)"/>
        </div>
        <div>{attendees.size} {attendees.size === 1 ? singular : plural}</div>
      </div>
    }
    {
      !!attendees.size &&
      <div className="AddAttendees-list">
        <List depth={1}>
          {
            removeFalsyFromArray([...attendees].map(user => members.find(member => member._id === user))).map(user => {
              return <ListItem
                key={user._id}
                iconName={getRoleIcon(user.role)}
                beforeName={<ProfilePicture value={user.profilePicture}/>}
                name={user.name}
              />
            })
          }
        </List>
      </div>
    }
  </>
}

export default AttendeesDisplay
