import host from '../host.js'
import post from './post.js'

function login (username, password) {
  const url = `${host}/auth/login`
  const options = {
    json: {
      email: username,
      password: password
    }
  }
  return post(url, options, { useAuth: false })
}

export default login
