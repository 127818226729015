/**
 * We use api.localtest.me on the development environment because the reverse proxy works with
 * subdomains. This method prevents us from adding local subdomains to the hosts file.
 *
 * https://readme.localtest.me/
 */

const host = process.env.NODE_ENV === 'development'
  ? 'http://api.localtest.me'
  : 'https://api.younited.be'
export default host
