import React from 'react'
import classes from './Version.module.scss'
import { version } from '../../version'

const Version = () => {
  return (
    <div className={classes.root}>
      {version}
    </div>
  )
}

export default Version
