import React from 'react'
import './App.scss'
import store from '../../redux/store.js'
import { Provider } from 'react-redux'
import Snackbar from '../Snackbar/Snackbar.js'
import MobileWarning from '../MobileWarning/MobileWarning.js'

const App = ({ children }) => {
  return (
    <Provider store={store}>
      <div className="App-overlay-container">
        <div id="floating-button"></div>
        <div className="App-overlay-snackbar"><Snackbar /></div>
      </div>
      <div className="App-root">
        {children}
      </div>
      <MobileWarning />
    </Provider>
  )
}

export default App
