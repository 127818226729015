import store from './redux/store.js'
import { setAppState } from './redux/appState/actions.js'

let alreadyDidOnce = false

window.addEventListener('beforeinstallprompt', function (e) {
  if (alreadyDidOnce) return
  alreadyDidOnce = true
  const deferredPrompt = e
  store.dispatch(setAppState({ canInstallPWA: true, deferredPrompt }))
})

function getIsStandalone () {
  try {
    if (!window.matchMedia('(display-mode: standalone)').matches) {
      store.dispatch(setAppState({ canInstallPWA: true }))
    }
  } catch (error) {}
}

getIsStandalone()
