import React from 'react'
import i18n from '../../translations/i18n.js'
import Text from '../../components/Text/Text.js'

const IOSNotSafari = () => {
  return (
    <div className="InstallAndroidOperaTouch">
      <div className="InstallAndroidOperaTouch-message">
        <Text size={14}>{i18n.t('installation:safari_browser_message')}</Text>
        <div>
          <img alt="safari" src="/image/safari.png" />
        </div>
      </div>
    </div>
  )
}

export default IOSNotSafari
