import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import './CreateMember.scss'
import i18n from '../../translations/i18n.js'
import Header from '../../components/Header/Header.js'
import history from '../../history.js'
import Button, { ButtonRow } from '../../components/Button/Button.js'
import Input, { InputContainer } from '../../components/Input/Input.js'
import PhoneInput from '../../components/PhoneInput/PhoneInput.js'
import Modal from '../../components/Modal/Modal.js'
import Text from '../../components/Text/Text.js'
import Select from '../../components/Select/Select.js'
import createUser from '../../connector/createUser.js'
import updateUser from '../../connector/updateUser.js'
import deleteUser from '../../connector/deleteUser.js'
import Icon from '../../components/Icon/Icon.js'
import { CreateSnackbarItem } from '../../components/Snackbar/Snackbar.js'
import { connect } from 'react-redux'
import getSizeItems from '../../common/sizeItems.js'
import getGenderItems from '../../common/genderItems.js'
import useUser from '../../customHooks/useUser.js'
import useScrollToInputOnResize from '../../customHooks/useScrollToInputOnResize.js'
import Loader from '../../components/Loader/Loader.js'
import ProfilePictureInput from '../../components/ProfilePictureInput/ProfilePictureInput.js'

const initialState = {
  role: 'player',
  name: '',
  gender: '',
  birthYear: '',
  shirtSize: '',
  shoeSize: '',
  phoneNumber: '',
  email: '',
  comment: '',
  updated: false
}

function reducer (state, action) {
  switch (action.type) {
    case 'setValue': {
      return {
        ...state,
        [action.name]: action.value,
        updated: true
      }
    }
    case 'setState': {
      return action.state
    }
    default:
      return state
  }
}

const TitleInput = ({ error, value, onChange, inputRef, disabled }) => {
  const classes = [
    'CreateMember-title',
    error && 'CreateMember-title-error',
    disabled && 'CreateMember-disabled'
  ]
  return (
    <div className={classes.join(' ')}>
      <input disabled={disabled} placeholder={i18n.t('common:name_member')} value={value} onChange={onChange}
             ref={inputRef}/>
      <div className="CreateMember-title-icon"><Icon name="danger" fill="var(--red)" width={24} height={24}/></div>
    </div>
  )
}

const CreateMember = ({ user, team, clubId, edit, editId, embedded, embeddedOnClose, embeddedOnNewMember }) => {
  const [missingValue, setMissingValue] = useState(false)
  const [state, dispatch] = useReducer(reducer, initialState)
  const [invalidShoesize, setInvalidShoesize] = useState(false)
  const [memberError, memberLoading, member] = useUser(editId)
  const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false)
  const [goBackConfirm, setGoBackConfirm] = useState(false)
  const [showBackWarning, setShowBackWarning] = useState(false)
  const [blockBackPress, setBlockBackPress] = useState(false)
  useScrollToInputOnResize()
  const fixForIos = useRef(null)

  const memberIsCoach = member.role === 'coach'
  const memberIsClubAdmin = member.role === 'clubadmin'
  const emailIsDisabled = edit && (memberIsCoach || memberIsClubAdmin)

  useEffect(() => {
    if (edit && member) {
      dispatch({
        type: 'setState',
        state: {
          role: member.role || '',
          name: member.name || '',
          profilePicture: member.profilePicture || null,
          clearProfilePicture: false,
          gender: member.gender || '',
          birthYear: member.birthYear || '',
          shirtSize: (member.shirtSize || '').toLowerCase(),
          shoeSize: member.shoeSize || '',
          phoneNumber: member.phoneNumber || '',
          email: member.email || '',
          comment: member.comment || ''
        }
      })
    }
  }, [edit, member])

  useEffect(() => {
    const size = state.shoeSize
    if (size && (size < 1 || size > 100)) {
      if (!invalidShoesize) setInvalidShoesize(true)
    } else {
      if (invalidShoesize) setInvalidShoesize(false)
    }
  }, [state.shoeSize, invalidShoesize])

  useEffect(() => {
    if (embedded) return
    if (blockBackPress) {
      const unblock = history.block((location, action) => {
        return '__ignore'
      })
      return unblock
    }

    if (!state.updated) return
    const unblock = history.block((location, action) => {
      if (goBackConfirm || fixForIos.current) return ''
      setShowBackWarning(true)
      return '__ignore'
    })
    return unblock
  }, [goBackConfirm, state.updated, blockBackPress, embedded])

  function handleBackClick (e) {
    if (embedded) {
      embeddedOnClose()
    } else {
      history.goBack()
    }
  }

  function handleDeleteClick (e) {
    if (memberIsCoach || memberIsClubAdmin) return
    setShowDeleteWarningModal(true)
  }

  function handleDeleteCancel () {
    setShowDeleteWarningModal(false)
  }

  async function handleDeleteConfirm () {
    try {
      await deleteUser(editId)
      setGoBackConfirm(true)
      fixForIos.current = true
      history.goBack()
    } catch (error) {
      console.error(error) // TODO handle error
    }
  }

  async function handleFormSubmit (e) {
    e.preventDefault()
    e.stopPropagation()
    if (!state.name || !state.birthYear || !state.gender) {
      return setMissingValue(true)
    }
    if (state.shoeSize) {
      const size = parseInt(state.shoeSize)
      if (size < 1 || size > 100) {
        CreateSnackbarItem({
          message: i18n.t('errors:invalid_shoesize'),
          level: 'error'
        })
        return setInvalidShoesize(true)
      }
    }
    let newMember
    if (edit) {
      newMember = {
        role: member.role,
        teams: member.teams,
        club: member.club
      }
    } else {
      newMember = {
        role: state.role,
        name: state.name,
        teams: [team._id],
        club: clubId
      }
    }
    if (state.name) newMember.name = state.name
    if (state.profilePicture instanceof File) newMember.profilePicture = state.profilePicture
    newMember.clearProfilePicture = state.clearProfilePicture
    if (state.gender) newMember.gender = state.gender
    if (state.birthYear) newMember.birthYear = parseInt(state.birthYear)
    if (state.shirtSize) newMember.shirtSize = state.shirtSize
    if (state.shoeSize) newMember.shoeSize = parseInt(state.shoeSize)
    if (state.phoneNumber) newMember.phoneNumber = state.phoneNumber
    if (state.comment) newMember.comment = state.comment
    if (state.email && !emailIsDisabled) newMember.email = state.email
    try {
      let message, newUser
      if (edit) {
        await updateUser(member._id, newMember)
        message = i18n.t('messages:member_edited', { name: state.name })
      } else {
        newUser = await createUser(newMember).json()
        if (embedded) {
          message = i18n.t('messages:new_member_created_and_selected', { name: state.name })
        } else {
          message = i18n.t('messages:new_member_created', { name: state.name })
        }
      }
      CreateSnackbarItem({
        message,
        level: 'success'
      })
      if (embedded) {
        embeddedOnNewMember(newUser)
      } else {
        setGoBackConfirm(true)
        fixForIos.current = true
        history.goBack()
      }
    } catch (error) {
      console.error(error)
      return CreateSnackbarItem({
        message: i18n.t('errors:something_went_wrong'),
        level: 'error'
      })
    }
  }

  function handleNameChange (e) {
    setMissingValue(false)
    dispatch({
      type: 'setValue',
      name: 'name',
      value: e.target.value
    })
  }

  function handleValueChange (name) {
    return e => {
      setMissingValue(false)
      dispatch({
        type: 'setValue',
        name,
        value: e.target.value
      })
    }
  }

  function handleSelectValueChange (name) {
    return value => {
      setMissingValue(false)
      dispatch({
        type: 'setValue',
        name,
        value
      })
    }
  }

  function handleChangeProfilePicture (value) {
    handleSelectValueChange('profilePicture')(value)
    handleSelectValueChange('clearProfilePicture')(false)
  }

  function handleClearProfilePicture () {
    handleSelectValueChange('profilePicture')(null)
    handleSelectValueChange('clearProfilePicture')(true)
  }

  function handleGoBackConfirm () {
    setGoBackConfirm(true)
    fixForIos.current = true
    history.goBack()
  }

  function enableBlockBackPress () {
    setBlockBackPress(true)
  }

  function disableBlockBackPress () {
    setBlockBackPress(false)
  }

  function validateBirthYear () {
    if (state.birthYear) {
      let newValue
      if (state.birthYear < 1950) {
        newValue = 1950
      } else if (state.birthYear > new Date().getFullYear()) {
        newValue = new Date().getFullYear()
      }
      if (newValue) {
        dispatch({
          type: 'setValue',
          name: 'birthYear',
          value: newValue
        })
      }
    }
  }

  const genderItems = getGenderItems()
  const sizeItems = getSizeItems()

  const inputRef = useCallback(node => {
    if (node && !edit) {
      node.focus()
    }
  }, [edit])

  const allDisabled = edit && (member.role !== 'player' && member._id !== user._id)

  return (
    <div>
      {memberError && <div>{memberError.toString()}</div>}
      <Modal active={showDeleteWarningModal} onClose={() => setShowDeleteWarningModal(false)}>
        <div className="CreateMember-modal">
          <div className="CreateMember-modal-warning"><Icon name="danger" fill="var(--red)" width={26} height={26}/>
          </div>
          <div className="CreateMember-modal-text"><Text size={14}>{i18n.t('messages:delete_attendee_warning')}</Text>
          </div>
          <ButtonRow>
            <Button onClick={handleDeleteCancel}>{i18n.t('actions:cancel')}</Button>
            <Button onClick={handleDeleteConfirm}>{i18n.t('actions:delete_attendee')}</Button>
          </ButtonRow>
        </div>
      </Modal>
      <Modal active={showBackWarning} onClose={() => setShowBackWarning(false)}>
        <div className="CreateMember-modal">
          <div className="CreateMember-modal-warning"><Icon name="danger" fill="var(--red)" width={26} height={26}/>
          </div>
          <div className="CreateActivity-back-modal"><Text
            size={14}>{i18n.t('messages:dont_save_member_warning')}</Text></div>
          <ButtonRow>
            <Button onClick={handleGoBackConfirm}>{i18n.t('actions:delete')}</Button>
            <Button onClick={() => setShowBackWarning(false)}>{i18n.t('actions:keep_editing')}</Button>
          </ButtonRow>
        </div>
      </Modal>
      <Header
        customTitle={<TitleInput error={missingValue} value={state.name} onChange={handleNameChange} inputRef={inputRef}
                                 disabled={allDisabled}/>}
        leftAction="back"
        onLeftAction={handleBackClick}
        rightAction={(edit && !allDisabled) ? 'delete' : null}
        onRightAction={handleDeleteClick}
      />
      <form onSubmit={handleFormSubmit}>
        <div className="CreateMember-body">
          {memberLoading && <Loader fullSize={true}/>}
          <InputContainer>
            <ConditionalDisplay hidden={shouldIHide(allDisabled, state.profilePicture)}>
              <ProfilePictureInput
                disabled={allDisabled}
                label={i18n.t('actions:set_profile_picture')}
                value={state.profilePicture}
                onChange={handleChangeProfilePicture}
                onClear={handleClearProfilePicture}
                onFocus={enableBlockBackPress}
                onBlur={disableBlockBackPress}
              />
            </ConditionalDisplay>
            <ConditionalDisplay hidden={shouldIHide(allDisabled, state.gender)}>
              <Select
                required={true}
                error={missingValue} iconName="gender"
                disabled={allDisabled}
                label={i18n.t('actions:add_gender')}
                items={genderItems}
                value={genderItems.find(item => item.id === state.gender)}
                onChange={handleSelectValueChange('gender')}
                onFocus={enableBlockBackPress}
                onBlur={disableBlockBackPress}
              />
            </ConditionalDisplay>
            <ConditionalDisplay hidden={shouldIHide(allDisabled, state.birthYear)}>
              <Input
                required={true}
                error={missingValue} iconName="present"
                disabled={allDisabled}
                label={i18n.t('actions:add_birthyear')} type="number"
                value={state.birthYear}
                onChange={handleValueChange('birthYear')}
                onBlur={validateBirthYear}
              />
            </ConditionalDisplay>
            <ConditionalDisplay hidden={shouldIHide(allDisabled, state.shirtSize)}>
              <Select
                iconName="clothing"
                disabled={allDisabled}
                label={i18n.t('actions:add_tshirt_size')}
                items={sizeItems}
                value={sizeItems.find(item => item.id === state.shirtSize)}
                onChange={handleSelectValueChange('shirtSize')}
                onFocus={enableBlockBackPress}
                onBlur={disableBlockBackPress}
              />
            </ConditionalDisplay>
            <ConditionalDisplay hidden={shouldIHide(allDisabled, state.shoeSize)}>
              <Input
                iconName="shoes"
                disabled={allDisabled}
                label={i18n.t('actions:add_shoesize')}
                type="number"
                value={state.shoeSize}
                onChange={handleValueChange('shoeSize')}
                error={invalidShoesize}
              />
            </ConditionalDisplay>
            <ConditionalDisplay hidden={shouldIHide(allDisabled, state.phoneNumber)}>
              <PhoneInput
                iconName="phone"
                disabled={allDisabled}
                label={i18n.t('actions:add_mobile')}
                value={state.phoneNumber}
                onChange={handleSelectValueChange('phoneNumber')}
                onFocus={enableBlockBackPress}
                onBlur={disableBlockBackPress}
              />
            </ConditionalDisplay>
            <ConditionalDisplay hidden={shouldIHide(allDisabled || emailIsDisabled, state.email)}>
              <Input
                iconName="email"
                disabled={allDisabled || emailIsDisabled}
                label={i18n.t('actions:add_email')}
                type="email"
                value={state.email}
                onChange={handleValueChange('email')}
              />
            </ConditionalDisplay>
            <ConditionalDisplay hidden={shouldIHide(allDisabled, state.comment)}>
              <Input
                iconName="talk"
                disabled={allDisabled}
                label={i18n.t('actions:add_comment')}
                value={state.comment}
                type="textarea"
                onChange={handleValueChange('comment')}
              />
            </ConditionalDisplay>
          </InputContainer>
        </div>
        <Button type="submit">{i18n.t('actions:save')}</Button>
      </form>
    </div>
  )
}

function ConditionalDisplay ({ hidden, children }) {
  return Boolean(hidden) === false && children
}

function shouldIHide (disabled, value) {
  return disabled && !value
}

function mapStateToProps (state) {
  const user = state.user.user || {}
  const club = user.club || {}
  return {
    team: state.appState.team,
    clubId: club._id,
    user
  }
}

export default connect(mapStateToProps)(CreateMember)
