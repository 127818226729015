import host from '../host.js'
import post from './post.js'

function requestPasswordReset (email) {
  const url = `${host}/auth/requestPasswordReset`
  const options = {
    json: {
      email
    }
  }
  return post(url, options, { useAuth: false })
}

export default requestPasswordReset
