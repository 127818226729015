// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/

let throttleTimeout = { counter: 0 }

function updateVhButNotTooOften () {
  let counter = throttleTimeout.counter + 1
  if (counter < 5) {
    clearTimeout(throttleTimeout.timeout)
  } else {
    counter = 0
  }
  throttleTimeout = {
    counter,
    timeout: setTimeout(updateVh, 100)
  }
}

function updateVh () {
  const vh = window.innerHeight * 0.01

  const innerHTML = `
    :root {
      --vh: ${vh}px;
    }
  `

  const existingStyleTag = document.getElementById('dynamic-vh')
  if (existingStyleTag) {
    existingStyleTag.innerHTML = innerHTML
  } else {
    const newStyleTag = document.createElement('style')
    newStyleTag.id = 'dynamic-vh'
    newStyleTag.innerHTML = innerHTML
    document.head.append(newStyleTag)
  }
}

window.addEventListener('resize', updateVhButNotTooOften)
updateVh()
