import React, { useState } from 'react'
import './RequestPasswordReset.scss'
import i18n from '../../translations/i18n.js'
import Text from '../../components/Text/Text.js'
import Input from '../../components/Input/Input.js'
import Button from '../../components/Button/Button.js'
import Icon from '../../components/Icon/Icon.js'
import requestPasswordReset from '../../connector/requestPasswordReset.js'
import { CreateSnackbarItem } from '../../components/Snackbar/Snackbar.js'
import Version from '../../components/Version/Version'

const RequestPasswordReset = ({ token }) => {
  const [email, setEmail] = useState('')
  const [missingEmail, setMissingEmail] = useState(false)
  const [sendSuccess, setSendSuccess] = useState(false)

  function handleEmailChange (e) {
    setMissingEmail(false)
    setEmail(e.target.value)
  }

  async function handleSubmit (e) {
    e.preventDefault()
    if (!email) return setMissingEmail(true)
    try {
      await requestPasswordReset(email)
      setSendSuccess(true)
    } catch (error) {
      CreateSnackbarItem({
        message: `Unhandled error: ${error.message}`,
        level: 'error'
      })
    }
  }
  const language = window.navigator.language

  if (sendSuccess) {
    return (
      <div className="RequestPasswordReset-root">
        <Version/>
        <div className="RequestPasswordReset-title RequestPasswordReset-title-sent">
          <Text size={15} weight="medium">{i18n.t('messages:password_reset_request_sent', { lng: language })}</Text>
        </div>
        <div className="RequestPasswordReset-message">
          <Text size={15} font="roboto">{i18n.t('messages:password_reset_request_sent_message', { lng: language })}</Text>
        </div>
        <div className="RequestPasswordReset-icons">
          <div>
            <Icon width={44} height={44} name="mobileIcon" />
            <Text transform="uppercase" size={8} weight="bold">{i18n.t('common:younited')}</Text>
          </div>
          <div className="RequestPasswordReset-icons-space"></div>
          <div>
            <Icon width={44} height={44} name="analyticsIcon" />
            <Text transform="uppercase" size={8} weight="bold">{i18n.t('common:younited_analytics')}</Text>
          </div>
        </div>
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit} className="RequestPasswordReset-root">
      <Version/>
      <div className="RequestPasswordReset-title">
        <Text size={15} weight="medium">{i18n.t('actions:request_password_reset', { lng: language })}</Text>
      </div>
      <div className="RequestPasswordReset-input-container">
        <label>
          <Text size={13} weight="light">{i18n.t('common:email', { lng: language })}</Text>
          <Input error={missingEmail} value={email} type="email" onChange={handleEmailChange} />
        </label>
      </div>
      <div className="RequestPasswordReset-submit">
        <Button type="submit">{i18n.t('actions:save', { lng: language })}</Button>
      </div>
      <div className="RequestPasswordReset-icons">
        <div>
          <Icon width={44} height={44} name="mobileIcon" />
          <Text transform="uppercase" size={8} weight="bold">{i18n.t('common:younited')}</Text>
        </div>
        <div className="RequestPasswordReset-icons-space"></div>
        <div>
          <Icon width={44} height={44} name="analyticsIcon" />
          <Text transform="uppercase" size={8} weight="bold">{i18n.t('common:younited_analytics')}</Text>
        </div>
      </div>
    </form>
  )
}

export default RequestPasswordReset
