import React from 'react'
import PropTypes from 'prop-types'
import './Button.scss'
import Text from '../Text/Text.js'
import Icon from '../Icon/Icon.js'
import removeFalsyFromArray from '../../removeFalsyFromArray.js'

const ButtonRow = ({ children }) => <div className="Button-row">{children}</div>

ButtonRow.propTypes = {
  children: PropTypes.node
}

const Button = ({ children, onClick, disabled, circle, type = 'button' }) => {
  const classes = removeFalsyFromArray([
    'Button-root',
    circle && 'Button-circle'
  ])
  const content = circle
    ? (
      <div className="Button-circle-content">
        <div><Icon name="plus" fill="var(--primary-contrast)" width={12} height={12} /></div>
        <Text weight="bold" size={12} color="primary-contrast" transform="uppercase">{children}</Text>
      </div>
      )
    : (
      <Text color={disabled ? 'white' : 'black'} size={12} weight="bold" transform="uppercase">{children}</Text>
      )
  return (
    <button disabled={disabled} onClick={onClick} className={classes.join(' ')} type={type}>
      {content}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  circle: PropTypes.bool,
  type: PropTypes.string
}

export default Button
export { ButtonRow }
