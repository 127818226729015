import { setUser } from './redux/user/actions.js'
import { setAppState } from './redux/appState/actions.js'
import getMe from './connector/getMe.js'
import store from './redux/store.js'
import { removeToken } from './token.js'
import { CreateSnackbarItem } from './components/Snackbar/Snackbar.js'
import i18n from './translations/i18n.js'
import { setTeamId, getTeamId } from './currentTeamId.js'

async function initializeUser () {
  try {
    const user = await getMe().json()
    if (!user.teams || !user.teams.length) {
      CreateSnackbarItem({
        message: i18n.t('user_has_no_teams'),
        level: 'error'
      })
      removeToken()
      return
    }
    store.dispatch(setUser(user))
    const teamId = getTeamId()
    if (!teamId) {
      setTeamId(user.teams[0]._id)
    }
    const team = user.teams.find(team => team._id === teamId) || user.teams[0]
    store.dispatch(setAppState({ team }))
  } catch (error) {
    // TODO handle error
  }
}

export default initializeUser
