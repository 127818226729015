import actions from './actions.json'
import activityTypes from './activityTypes.json'
import common from './common.json'
import errors from './errors.json'
import installation from './installation.json'
import messages from './messages.json'

const translations = {
  actions,
  activityTypes,
  common,
  errors,
  installation,
  messages
}

export default translations
