import { useState, useEffect } from 'react'
import getUser from '../connector/getUser.js'

function useUser (id) {
  const [user, setUser] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let removed = false
    async function get () {
      if (!id) return
      setLoading(true)
      try {
        const user = await getUser(id).json()
        if (removed) return
        setUser(user)
        setLoading(false)
      } catch (error) {
        if (removed) return
        setError(error)
        setLoading(false)
      }
    }
    get()
    return () => { removed = true }
  }, [id])

  return [error, loading, user]
}

export default useUser
