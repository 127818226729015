import React, { useState, useMemo, useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'
import './Activities.scss'
import i18n from '../../translations/i18n.js'
import { connect } from 'react-redux'
import Header from '../../components/Header/Header.js'
import Navigation from '../../components/Navigation/Navigation.js'
import history from '../../history.js'
import Button from '../../components/Button/Button.js'
import useActivities from '../../customHooks/useActivities.js'
import Activity from '../../components/Activity/Activity.js'
import { startOfMonth, addMonths, addYears, getDate, setDate, isToday, addDays, isBefore, startOfDay } from 'date-fns'
import format from '../../format.js'
import Text from '../../components/Text/Text.js'
import Icon from '../../components/Icon/Icon.js'
import { setAppState } from '../../redux/appState/actions.js'
import removeFalsyFromArray from '../../removeFalsyFromArray.js'
import Loader from '../../components/Loader/Loader.js'
import { useSwipeable } from 'react-swipeable'
import Bowser from 'bowser'

// load previous 6 months when scrolling back
// load today + 2 years onload

function getElToShow (ref) {
  const today = new Date()
  const date = format(today, 'yyyy-MM')
  let elToShow = ref.current.querySelector(`#dateid-${date}`)
  if (!elToShow) {
    const availableMonths = removeFalsyFromArray([...ref.current.children[0].children].map(c => c.id))
    // find first available month in the future
    let match
    match = availableMonths.find(month => {
      if (!month) return false
      // create dates this way to prevent issues in safari
      const splitDate = month.slice(7).split('-')
      splitDate[1] -= 1
      return isBefore(today, new Date(...splitDate))
    })
    // else first last available month in the past
    if (!match) {
      availableMonths.reverse()
      match = availableMonths.find(month => {
        if (!month) return false
        // create dates this way to prevent issues in safari
        const splitDate = month.slice(7).split('-')
        splitDate[1] -= 1
        return isBefore(today, new Date(...splitDate))
      })
    }
    if (!match) {
      match = availableMonths[0]
    }
    if (match) {
      elToShow = ref.current.querySelector(`#${match}`)
    }
  }
  return elToShow
}

function scrollToToday (ref) {
  const elToShow = getElToShow(ref)
  if (!elToShow) return
  elToShow.scrollIntoView()
}

function groupByMonth (activities) {
  const groupedByMonth = new Map()
  activities.forEach(activity => {
    const date = format(new Date(activity.date), 'yyyy MM')
    groupedByMonth.set(date, [...groupedByMonth.get(date) || [], activity])
  })
  return groupedByMonth
}

function sortByMonth (activities) {
  return new Map([...activities.keys()].sort((a, b) => {
    let num = 0
    if (a < b) num = -1
    if (a > b) num = 1
    return num
  }).map(date => [date, activities.get(date)]))
}

function groupAndSortByMonth (activities) {
  const groupedByMonth = groupByMonth(activities)
  const sortedByMonth = sortByMonth(groupedByMonth)
  return sortedByMonth
}

const Activities = ({ teams, team, canInstallPWA, deferredPrompt, setInstallationResponse, pwaMessageClicked }) => {
  const startDate = addMonths(startOfMonth(new Date()), -6)
  const endDate = startOfMonth(addYears(new Date(), 2))
  const teamId = team && team._id
  const [activitiesError, activitiesLoading, activities] = useActivities({
    teamId,
    from: format(startDate, 'yyyy-MM-dd'),
    to: format(endDate, 'yyyy-MM-dd')
  })
  const bodyRef = useRef(null)
  const [swipePosition, setSwipePosition] = useState(false)

  function handleSwiping (e) {
    if (e.deltaX > 20 && e.absX > e.absY) {
      if (!swipePosition) setSwipePosition(true)
    } else {
      if (swipePosition) setSwipePosition(false)
    }
  }
  function handleSwipe (e) {
    history.replace('/team')
  }
  const handlers = useSwipeable({ onSwiped: () => setSwipePosition(false), onSwipedLeft: handleSwipe, onSwiping: handleSwiping, delta: 20 })

  const bowser = useMemo(() => {
    return Bowser.parse(window.navigator.userAgent)
  }, [])

  useEffect(() => {
    if (bodyRef.current && !activitiesLoading) {
      scrollToToday(bodyRef)
    }
  }, [activitiesLoading])

  const byMonth = useMemo(() => {
    return groupAndSortByMonth(activities)
  }, [activities])

  function handleSettingsClick (e) {
    history.push('/settings')
  }

  function handleAddClick (e) {
    history.push('/createActivity')
  }

  function handleInstallClick () {
    setInstallationResponse()
    if (deferredPrompt || bowser.browser.name === 'Chrome' || bowser.browser.name === 'Chromium') {
      if (!deferredPrompt) return
      deferredPrompt.prompt()
      deferredPrompt.userChoice.then((choiceResult) => {
        // choiceResult.outcome can be 'accepted' or 'dismissed', but we're just going to hide the message anyway
      })
    } else {
      history.push('/install')
    }
  }

  function handleDateClick () {
    scrollToToday(bodyRef)
  }

  const loading = activitiesLoading
  const error = activitiesError // TODO handle error

  const bodyClasses = removeFalsyFromArray(['Activities-body', swipePosition && 'Activities-swiping'])

  return (
    <div className="Activities-root">
      { error && <div>{error.toString()}</div> }
      <Header
        title={i18n.t('common:activities')}
        subTitle={team.name}
        leftAction='settings'
        onLeftAction={handleSettingsClick}
        rightAction='date'
        onRightAction={handleDateClick}
      />
      <div className={bodyClasses.join(' ')} ref={bodyRef}>
        <div {...handlers}>
          { loading && <Loader fullSize={true} /> }
          { !pwaMessageClicked && bowser.platform.type === 'mobile' && canInstallPWA &&
            <PWARow handleInstallClick={handleInstallClick} bodyRef={bodyRef} />
          }
          { [...byMonth].map(([key, activities]) => {
            // create dates this way to prevent issues in safari
            const splitDate = key.split(' ')
            splitDate[1] -= 1
            return <MonthActivities key={key} activities={activities} date={new Date(...splitDate)} />
          }) }
          <div className="Activities-body-spacing"></div>
        </div>
      </div>
      <Navigation page="activities" />
      <CreateActivityButton handleAddClick={handleAddClick} />
    </div>
  )
}

const CreateActivityButton = ({ handleAddClick }) => {
  if (!document.getElementById('floating-button')) return null
  return createPortal(
    <div>
      <Button circle={true} onClick={handleAddClick}>{i18n.t('actions:add_activity')}</Button>
    </div>,
    document.getElementById('floating-button')
  )
}

const MonthActivities = ({ activities, date }) => {
  const byDay = useMemo(() => {
    const days = []
    activities.forEach(activity => {
      const day = getDate(new Date(activity.date))
      days[day] = [...days[day] || [], activity]
    })
    return days
  }, [activities])

  function handleEditClick (activity) {
    return e => {
      history.push(`/editActivity/${activity._id}`)
    }
  }

  const tomorrow = startOfDay(addDays(new Date(), 1))

  return (
    <div className="Activities-month-root" id={`dateid-${format(date, 'yyyy-MM')}`}>
      <div className="Activities-month-pwa-container"></div>
      <div className="Activities-month-title"><Text size={12} transform="uppercase">{format(date, 'MMMM - yyyy')}</Text></div>
      {
        byDay.map((activities, i) => {
          const thisDate = setDate(date, i)
          const dateIsToday = isToday(thisDate)
          const oldEnough = isBefore(thisDate, tomorrow)
          return (
            <div key={i} className="Activities-day">
              <div className="Activities-day-date">
                <Text size={12} transform="uppercase">{format(thisDate, 'EEEEEE')}</Text>
                <div className={dateIsToday ? 'Activities-day-today' : ''}><Text size={15} weight="bold">{i}</Text></div>
              </div>
              <div className="Activities-day-activities">
                { activities.map(activity => {
                  const totalAttendees = activity.attendingTeams.reduce((accumulator, currentValue) => {
                    return accumulator + (currentValue.attendees || []).length + (currentValue.coaches || []).length
                  }, 0)
                  return <div key={activity._id} className="Activities-day-activity">
                    <div className="Activities-day-activity-warning">
                      { !totalAttendees && oldEnough && <Icon name="danger" width={24} height={24} fill="var(--red)" /> }
                    </div>
                    <Activity name={activity.activityType} amount={totalAttendees} solid={!!totalAttendees} description={activity.description} onClick={handleEditClick(activity)} tooSoon={!oldEnough} />
                  </div>
                }) }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

const PWARow = ({ handleInstallClick, bodyRef }) => {
  if (!bodyRef.current) return null
  const closestMonth = getElToShow(bodyRef)
  if (!closestMonth) return null
  const myContainer = closestMonth.getElementsByClassName('Activities-month-pwa-container')[0]
  if (!myContainer) return null
  return createPortal(
    <div className="Activities-installation">
      <div className="Activities-installation-icon"><Icon name="mobileIcon" width={28} height={28} /></div>
      <div className="Activities-installation-center"><Text size={14}>Installeer de app op jouw smartphone!</Text></div>
      <button className="Activities-installation-button" onClick={handleInstallClick}><Text size={12} weight="bold">Installeer</Text></button>
    </div>,
    myContainer
  )
}

function mapStateToProps (state) {
  const user = state.user.user || {}
  return {
    team: state.appState.team,
    canInstallPWA: state.appState.canInstallPWA,
    pwaMessageClicked: state.appState.pwaMessageClicked,
    deferredPrompt: state.appState.deferredPrompt,
    teams: user.teams || []
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setInstallationResponse: () => dispatch(setAppState({
      canInstallPWA: false,
      deferredPrompt: null,
      pwaMessageClicked: true
    }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Activities)
