import { createBrowserHistory } from 'history'

const history = createBrowserHistory({
  getUserConfirmation: function (message, continueNavigation) {
    if (message === '__ignore') {
      return continueNavigation(false)
    }
    return continueNavigation(true)
  }
})

export default history
