import React, { useEffect, useState } from 'react'
import './ProfilePictureInput.scss'
import PropTypes from 'prop-types'
import removeFalsyFromArray from '../../removeFalsyFromArray.js'
import Input from '../Input/Input.js'
import ProfilePicture from '../ProfilePicture/ProfilePicture.js'
import Icon from '../Icon/Icon.js'
import Button from '../Button/Button.js'
import Text from '../Text/Text'

const ProfilePictureInput = ({ onChange, label, value, disabled, onClear, onFocus, onBlur }) => {
  const classes = removeFalsyFromArray([
    'ProfilePictureInput-root',
    disabled && 'ProfilePictureInput-disabled'
  ])

  const [displayValue, setDisplayValue] = useState(value)
  const inputRef = React.createRef()

  useEffect(() => {
    if (value) {
      setDisplayValue(value)
    }
  }, [value])

  async function encodeImageFileAsBuffer (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = function () {
        resolve({
          type: 'Buffer',
          data: Array.from(new Uint8Array(this.result))
        })
      }
      reader.readAsArrayBuffer(file)
    })
  }

  function handleInputChange (e) {
    if (disabled) return
    const file = e.target.files?.[0]
    if (file) {
      onChange(file)
      encodeImageFileAsBuffer(file).then((value) => {
        setDisplayValue(value)
      })
    }
  }

  function handleClear (event) {
    event.preventDefault()
    event.stopPropagation()
    if (inputRef.current?.value) {
      inputRef.current.value = null
    }
    setDisplayValue(null)
    onClear()
  }

  return (
    <label className={classes.join(' ')}>
      <div className="ProfilePictureInput-icon">
        <Icon width={16} height={16} fill="var(--grey) " name="person"/>
      </div>
      <div className="ProfilePictureInput-input-container">
        <Input ref={inputRef} accept="image/*" disabled={disabled} label={label} type="file" onChange={handleInputChange}/>
      </div>
      <ProfilePicture value={displayValue} onFocus={onFocus} onBlur={onBlur}/>
      <div className="ProfilePictureInput-label">
        <Text size={14} color={'grey'}>{label}</Text>
      </div>
      {
        displayValue && <div className="ProfilePictureInput-delete-button-container">
          <Button onClick={handleClear}>
            <Icon name="delete" />
          </Button>
        </div>
      }
    </label>
  )
}

ProfilePictureInput.propTypes = {
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.oneOf(['Buffer']).isRequired,
      data: PropTypes.arrayOf(PropTypes.number).isRequired
    }),
    PropTypes.instanceOf(File)
  ]),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
}

export default ProfilePictureInput
