import React from 'react'
import Login from './pages/Login/Login.js'
import Activities from './pages/Activities/Activities.js'
import Team from './pages/Team/Team.js'
import Settings from './pages/Settings/Settings.js'
import CreateMember from './pages/CreateMember/CreateMember.js'
import CreateActivity from './pages/CreateActivity/CreateActivity.js'
import EditUser from './pages/EditUser/EditUser.js'
import RequestPasswordReset from './pages/RequestPasswordReset/RequestPasswordReset.js'
import Install from './pages/PWAInstallPages/Install.js'
import { resetSnackbar, setSnackbarPosition } from './components/Snackbar/Snackbar.js'

const routes = [
  {
    path: '/',
    action: () => ({ redirect: '/activities' })
  },
  {
    path: '/activities',
    action: () => {
      resetSnackbar()
      return <Activities />
    }
  },
  {
    path: '/login',
    action: () => {
      resetSnackbar()
      return <Login />
    }
  },
  {
    path: '/team',
    action: () => {
      resetSnackbar()
      return <Team />
    }
  },
  {
    path: '/settings',
    action: () => {
      resetSnackbar()
      setSnackbarPosition(0)
      return <Settings />
    }
  },
  {
    path: '/createMember',
    action: () => {
      resetSnackbar()
      return <CreateMember />
    }
  },
  {
    path: '/editMember/:id',
    action: (context, params) => {
      resetSnackbar()
      return <CreateMember editId={params.id} edit={true} />
    }
  },
  {
    path: '/createActivity',
    action: () => {
      resetSnackbar()
      return <CreateActivity />
    }
  },
  {
    path: '/editActivity/:id',
    action: (context, params) => {
      resetSnackbar()
      return <CreateActivity editId={params.id} edit={true} />
    }
  },
  {
    path: '/editUser',
    action: () => {
      resetSnackbar()
      return <EditUser />
    }
  },
  {
    path: '/requestPasswordReset',
    action: () => {
      resetSnackbar()
      return <RequestPasswordReset />
    }
  },
  {
    path: '/install',
    action: () => {
      resetSnackbar()
      return <Install />
    }
  }
]

export default routes
