import { useState, useEffect } from 'react'
import getActivities from '../connector/getActivities.js'

function useActivities ({ teamId, from, to }) {
  const [activities, setActivities] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let removed = false
    async function get () {
      setLoading(true)
      try {
        const activities = await getActivities({ teamId, from, to }).json()
        if (removed) return
        setActivities(activities)
        setLoading(false)
      } catch (error) {
        if (removed) return
        setError(error)
        setLoading(false)
      }
    }
    get()
    return () => { removed = true }
  }, [teamId, from, to])

  return [error, loading, activities]
}

export default useActivities
